import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import * as mapboxgl from 'mapbox-gl'
import { identityPoolCredentials } from 'src/app/shared/services/auth/auth.service'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { MapBoxAPI } from '../../../../../../../../common/constants/mapAPI'

@Component({
    selector: 'app-gh-location-selector-mapbox-gl',
    templateUrl: './gh-location-selector-mapbox-gl.component.html',
    styleUrls: ['./gh-location-selector-mapbox-gl.component.scss'],
})
export class GhLocationSelectorMapboxGLComponent implements OnInit {
    @ViewChild('map', { static: false }) map

    constructor() {}

    // shouldInit = false ! slkdfajdaslkjdasflkjadsflkfadsjlkfdasjfsdalkjd slkjd
    shouldInit = true

    @Input() set _shouldInit(shouldInit) {
        this.shouldInit = shouldInit
    }

    @Output() addressChangeEmitter = new EventEmitter()

    ngOnInit() {
        this.initializeMap()
    }

    hasChecked = false
    ngAfterViewChecked() {
        if (!this.hasChecked && this.shouldInit) {
            this.initializeMap()
            this.hasChecked = true
        }
    }

    selectedLocation = {
        address: '?',
        neighborhood: '?',
        zipCode: '?',
        city: '?',
        county: '?',
        state: '?',
        country: '?',
        coordinates: '?',
    }

    async initializeMap() {
        if (!this.map) {
            return
        } // gets rid of annoying error in console

        let credentials = identityPoolCredentials
        await credentials.getPromise()

        const map = new mapboxgl.Map({
            container: 'map',
            // center: [-92.4802, 44.0121], // Rochester MN
            // center: [-82.9988, 39.9612], // Columbus OH
            center: [-95.9345, 41.2565], // Omaha NE
            style: 'mapbox://styles/mapbox/streets-v11',
            accessToken: MapBoxAPI,
            zoom: 3,
            minZoom: 3,
            maxZoom: 16,
        })

        map.addControl(new mapboxgl.NavigationControl(), 'top-left') //

        const coordinatesGeocoder = function (query) {
            const matches = query.match(
                /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i,
            )
            if (!matches) {
                return null
            }

            function coordinateFeature(lng, lat) {
                return {
                    center: [lng, lat],
                    geometry: {
                        type: 'Point',
                        coordinates: [lng, lat],
                    },
                    place_name: 'Lat: ' + lat + ' Lng: ' + lng,
                    place_type: ['coordinate'],
                    properties: {},
                    type: 'Feature',
                }
            }

            const coord1 = Number(matches[1])
            const coord2 = Number(matches[2])
            const geocodes = []

            if (coord1 < -90 || coord1 > 90) {
                // must be lng, lat
                geocodes.push(coordinateFeature(coord1, coord2))
            }

            if (coord2 < -90 || coord2 > 90) {
                // must be lat, lng
                geocodes.push(coordinateFeature(coord2, coord1))
            }

            if (geocodes.length === 0) {
                // else could be either lng, lat or lat, lng
                geocodes.push(coordinateFeature(coord1, coord2))
                geocodes.push(coordinateFeature(coord2, coord1))
            }
            return geocodes
        }

        map.addControl(
            new MapboxGeocoder({
                accessToken: MapBoxAPI,
                localGeocoder: coordinatesGeocoder,
                zoom: 12,
                placeholder: 'Enter Address',
                mapboxgl: mapboxgl,
                reverseGeocode: true,
            }).on('result', (selectedLocation) => {
                this.reshapeDataAndEmit(selectedLocation)
            }),
        )
    }

    reshapeDataAndEmit(input) {
        // if (!this.selectedLocation.zipCode) {
        //   this.initializeMap()
        //   throw new Error("No Zip Code");
        // }

        // if (this.selectedLocation.zipCode == '?') {
        //   alert('need a zip code for many features')
        //   this.initializeMap()
        //   throw new Error("error no zip");

        // }

        this.selectedLocation.address = input.result.place_name
        this.selectedLocation.coordinates = input.result.geometry.coordinates

        for (let item of input.result.context) {
            if (item.id.startsWith('neighborhood.')) {
                this.selectedLocation.neighborhood = item.text
            }

            if (item.id.startsWith('postcode.')) {
                this.selectedLocation.zipCode = item.text
            }

            if (item.id.startsWith('place.')) {
                this.selectedLocation.city = item.text
            }

            if (item.id.startsWith('district.')) {
                this.selectedLocation.county = item.text
            }

            if (item.id.startsWith('region.')) {
                this.selectedLocation.state = item.text
            }

            if (item.id.startsWith('country.')) {
                this.selectedLocation.country = item.text
            }

            // IS THIS WERE WE WANT TO PASS LAT LNG?
        }

        console.log('this.selectedLocation999 ', this.selectedLocation)
        this.addressChangeEmitter.emit(this.selectedLocation)
    }
}
