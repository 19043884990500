import { Injectable } from '@angular/core'
import { iUser } from '../../models/user.model'
import { CognitoUserPool, CognitoUser, CognitoUserAttribute, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js'
import AWS from 'aws-sdk'
import { environment } from 'src/environments/environment'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { Router } from '@angular/router'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'

const POOL_DATA = {
  // UserPoolId: 'us-east-1_cMpvKplsn',
  // ClientId: '1ga9a1vt4n2to55d336c5tjms6',
  UserPoolId: environment.cognitoUserPoolId,
  ClientId: environment.cognitoUserPoolWebClientId,
}

const userPool = new CognitoUserPool(POOL_DATA)

const identityPoolId = "us-east-1:dac0c245-fa9c-4c97-8242-f4426c308b79"
AWS.config.region = identityPoolId.split(":")[0]

export const identityPoolCredentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: identityPoolId,
})

@Injectable()
export class AuthService {

  shouldResetLoginForm = new BehaviorSubject(false)
  shouldResetLoginForm$ = this.shouldResetLoginForm.asObservable()

  authIsLoading = new BehaviorSubject<boolean>(false)

  authDidFail = new BehaviorSubject<boolean>(true) // want to default to true so that login component doesn't navigate to dashboard before authenticated
  authDidFail$ = this.authDidFail.asObservable()


  authStatusChanged = new Subject<boolean>()
  registeredUser: CognitoUser

  constructor(private router: Router, private backendAPIUsersService: BackendAPIUsersService) { }

  getCurrentUserId() {
    return this.getAuthenticatedUser().getUsername().toUpperCase()
  }


  signUp(username: string, email: string, password: string): void {
    this.authIsLoading.next(true)
    const user: iUser = {
      username: username,
      email: email,
      password: password
      // zipCode: zipCode,
    }
    const attributeList: CognitoUserAttribute[] = []
    const emailAttribute = {
      Name: 'email',
      Value: user.email
    }
    attributeList.push(new CognitoUserAttribute(emailAttribute))
    userPool.signUp(user.username, user.password, attributeList, null, (err, result) => {
      if (err) {
        this.authDidFail.next(true)
        this.authIsLoading.next(false)
        return
      }
      else {
        this.authDidFail.next(false)
        this.authIsLoading.next(false)
        this.registeredUser = result.user
      }
    })
    return
  }

  async confirmUser(username: string, code: string) {
    this.authIsLoading.next(true)
    const userData = {
      Username: username,
      Pool: userPool
    }


    const cognitoUser = new CognitoUser(userData)
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        this.authDidFail.next(true)
        this.authIsLoading.next(false)
        return
      }
      else {
        this.authDidFail.next(false)
        this, this.authIsLoading.next(false)
      }
    })
  }


  async signIn(username: string, password: string) {
    this.authIsLoading.next(true)
    const authData = {
      Username: username,
      Password: password
    }
    const authDetails = new AuthenticationDetails(authData)
    const userData = {
      Username: username,
      Pool: userPool
    }
    const cognitoUser = new CognitoUser(userData)

    const that = this

    await cognitoUser.authenticateUser(authDetails, {
      onSuccess(result: CognitoUserSession) {
        console.log('login success res', result)
        that.authStatusChanged.next(true)
        that.authDidFail.next(false)
        that.authIsLoading.next(false)
        that.backendAPIUsersService.initCurrentUser()
        that.router.navigate(['/dashboard'])
      },
      onFailure(err) {
        console.log('login error res', err)
        that.authStatusChanged.next(false)
        that.authDidFail.next(true)
        that.authIsLoading.next(false)
      }
    })
    return that.authDidFail.value


  }

  forgotPassword(username: string) {
    const userData = {
      Username: username,
      Pool: userPool
    }
    const cognitoUser = new CognitoUser(userData)

    const that = this

    cognitoUser.forgotPassword({
      onSuccess(result: CognitoUserSession) {
        that.shouldResetLoginForm.next(true)
        console.log('success!', result)
      },
      onFailure(err) {
        console.log('fail!', err)
      },
      async inputVerificationCode() {
        var verificationCode = prompt('Please input verification code', '')
        var newPassword = prompt('Enter new password', '')
        await cognitoUser.confirmPassword(verificationCode, newPassword, this)

      }
    })
  }

  resendActivationCode(username: string) {
    const userData = {
      Username: username,
      Pool: userPool
    }
    const cognitoUser = new CognitoUser(userData)
    cognitoUser.resendConfirmationCode((error: any, result: any) => {
      if (error) {
        console.log(error)
      } else {
        console.log(result)
      }
    })
  }

  getAuthenticatedUser() {
    return userPool.getCurrentUser()
  }

  async logout() {
    this.getAuthenticatedUser().signOut()
    localStorage.clear()
    window.localStorage.clear();
    this.authStatusChanged.next(false)
    this.checkAuth() //! needed to trigger subscription change
  }

  isAuthenticated(): Observable<boolean> {
    const user = this.getAuthenticatedUser()
    const obs = Observable.create((observer) => {
      if (!user) {
        observer.next(false)
      } else {
        user.getSession((err, session) => {
          if (err) {
            observer.next(false)
          }
          else {
            if (session.isValid()) {
              observer.next(true)
            }
            else {
              observer.next(false)
            }
          }
        })
      }
      observer.complete()
    })
    return obs
  }

  checkAuth() {
    this.isAuthenticated().subscribe(
      (auth) => {
        this.authStatusChanged.next(auth)
      }
    )
  }

}
