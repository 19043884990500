<div *ngIf="!waitingOnConfirmation" class="not-waiting-container">

    <div class="signup-error-container text-center" *ngIf="didFail">
        <mat-card-subtitle class="gh-md-subtitle">
            Incorrect Username or Password. Please Try Again.
        </mat-card-subtitle>
    </div>

    <div class="row mx-0 sign-up-container">
        <form [formGroup]="signupForm">

            <div class="inner-flex-container">
                <div class="col-12">

                    <div class="row mb-3">
                        <!-- todo <span class="inline-span mr-4 gh-checkbox-container" >
                            <mat-checkbox (change)="toggleProfessionalAccount($event)">Create Professional Account
                            </mat-checkbox>
                        </span> -->
                        <!-- 
                        <span class="inline-span right-span">
                            <mat-icon class="gh-icon gh-icon-clickable gh-info-icon" (click)="showProfessionalAccountInfo()">help</mat-icon>
                        </span> -->
                    </div>

                    <div class="row">

                        <div class="col-12">
                            <mat-form-field class="gh-form-field">
                                <mat-label>Email:</mat-label>
                                <!-- <input ngModel [email]="true" matInput type="text" formControlName="email"> -->
                                <input matInput type="text" formControlName="email">
                            </mat-form-field>
                            <mat-error class="gh-mat-error-simulator"
                                *ngIf="submitIsClicked && signupForm.get('email').errors?.required">
                                Required</mat-error>
                            <mat-error class="gh-mat-error-simulator"
                                *ngIf="submitIsClicked && signupForm.get('email').errors?.email">
                                Not a valid email address</mat-error>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="row">
                        <div class="col-5">
                            <mat-form-field class="gh-form-field" style="width: 100%;">
                                <mat-label>Password:</mat-label>
                                <input matInput *ngIf="!showPassword" type="password" formControlName="password"
                                    (focus)="onPasswordFocus()" (blur)="onPasswordBlur()">
                                <input matInput *ngIf="showPassword" type="text" formControlName="password"
                                    (focus)="onPasswordFocus()" (blur)="onPasswordBlur()">
                            </mat-form-field>
                            <mat-error class="gh-mat-error-simulator"
                                *ngIf="submitIsClicked && signupForm.get('password').errors?.required">
                                Required</mat-error>
                        </div>

                        <div class="col-5">
                            <mat-form-field class="gh-form-field">
                                <mat-label>Confirm Password:</mat-label>
                                <input matInput *ngIf="!showPassword" type="password" formControlName="confirmPassword"
                                    (focus)="onPasswordFocus()" (blur)="onPasswordBlur()">
                                <input matInput *ngIf="showPassword" type="text" formControlName="confirmPassword"
                                    (focus)="onPasswordFocus()" (blur)="onPasswordBlur()">
                            </mat-form-field>
                            <mat-error class="gh-mat-error-simulator"
                                *ngIf="submitIsClicked && signupForm.get('confirmPassword').errors?.required">
                                Required</mat-error>
                            <mat-error class="gh-mat-error-simulator"
                                *ngIf="submitIsClicked && signupForm.get('confirmPassword').errors.mustMatch">
                                Passwords Don't Match</mat-error>
                        </div>

                        <div class="col-2" matTooltip="Toggle Show Password" matTooltipPosition="above">
                            <mat-icon *ngIf="showPassword" (click)="togglePasswordVisibility()"
                                class="gh-icon gh-icon-clickable gh-icon-success">
                                visibility</mat-icon>
                            <mat-icon *ngIf="!showPassword" (click)="togglePasswordVisibility()"
                                class="gh-icon gh-icon-clickable gh-icon-warning">
                                visibility_off</mat-icon>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="password-hint-container">
        <div class="password-hint-inner-container">


            <div class="row mt-1">
                <div class="col-12 text-center">
                    <mat-card-title class="gh-md-subtitle">
                        Creating this INDIVIDUAL account will also allow you to create and manage a gaming group or
                        business.

                    </mat-card-title>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12 text-center">
                    <mat-card-subtitle class="gh-md-subtitle">
                        By continuing, you agree to Game Haven's <span style="cursor: pointer;"
                            (click)="openTerms()"><strong>Terms of Use</strong></span> and <span
                            style="cursor: pointer;" (click)="openPrivacyPolicy()"><strong>Privacy
                                Policy</strong></span>.
                    </mat-card-subtitle>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-12 text-center">
                    <mat-card-subtitle class="gh-md-subtitle">
                        We will <strong>NOT</strong> sell or share your email.
                    </mat-card-subtitle>
                </div>
            </div>

            <!-- <div class="row mt-1">
                <div class="col-12 text-center">
                    <mat-card-subtitle class="gh-md-subtitle">
                        Passwords must be at least 8 characters long and contain:
                    </mat-card-subtitle>
                </div>
            </div> -->



            <div class="row mt-3">
                <div class="col-12 text-center">

                    <span class="inline-span">
                        <mat-icon class="gh-icon password-satisfaction-icon"
                            [ngClass]="{ satisfiedCriterion: getRequirementIsSatisfied('length'), unsatisfiedCriterion: !getRequirementIsSatisfied('length') }">
                            {{ getRequirementIsSatisfied('lowercaseLetter') ? 'check' : 'close' }}</mat-icon>
                    </span>

                    <span class="inline-span ml-3">
                        <mat-card-subtitle class="password-length-subtitle"
                            [ngClass]="{ satisfiedCriterion: getRequirementIsSatisfied('length'), unsatisfiedCriterion: !getRequirementIsSatisfied('length') }">
                            Password Length: {{signupForm.get('password').value.length}}
                        </mat-card-subtitle>
                    </span>

                </div>
            </div>
            <div class="row">
                <div class="col-6 no-padding">
                    <mat-list role="list">
                        <mat-list-item class="mt-3 no-padding" role="listitem">
                            <span class="inline-span">
                                <mat-icon class="gh-icon password-satisfaction-icon"
                                    [ngClass]="{ satisfiedCriterion: getRequirementIsSatisfied('lowercaseLetter'), unsatisfiedCriterion: !getRequirementIsSatisfied('lowercaseLetter') }">
                                    {{ getRequirementIsSatisfied('lowercaseLetter') ? 'check' : 'close' }}</mat-icon>
                            </span>

                            <span class="inline-span ml-3">
                                <mat-card-subtitle
                                    [ngStyle]="{ color: getRequirementIsSatisfiedColor('lowercaseLetter') }">Lowercase</mat-card-subtitle>
                            </span>
                        </mat-list-item>
                        <mat-list-item class="mt-3 no-padding" role="listitem" class="bottom-item">
                            <span class="inline-span">
                                <mat-icon class="gh-icon password-satisfaction-icon"
                                    [ngClass]="{ satisfiedCriterion: getRequirementIsSatisfied('uppercaseLetter'), unsatisfiedCriterion: !getRequirementIsSatisfied('uppercaseLetter') }">
                                    {{ getRequirementIsSatisfied('uppercaseLetter') ? 'check' : 'close' }}</mat-icon>
                            </span>

                            <span class="inline-span ml-3">
                                <mat-card-subtitle
                                    [ngStyle]="{ color: getRequirementIsSatisfiedColor('uppercaseLetter') }">Uppercase</mat-card-subtitle>
                            </span>
                        </mat-list-item>
                    </mat-list>
                </div>
                <div class="col-6 no-padding">
                    <mat-list role="list">
                        <mat-list-item class="mt-3 no-padding" role="listitem">
                            <span class="inline-span">
                                <mat-icon class="gh-icon password-satisfaction-icon"
                                    [ngClass]="{ satisfiedCriterion: getRequirementIsSatisfied('number'), unsatisfiedCriterion: !getRequirementIsSatisfied('number') }">
                                    {{ getRequirementIsSatisfied('number') ? 'check' : 'close' }}</mat-icon>
                            </span>
                            <span class="inline-span ml-3">
                                <mat-card-subtitle
                                    [ngStyle]="{ color: getRequirementIsSatisfiedColor('number') }">Number</mat-card-subtitle>
                            </span>
                        </mat-list-item>
                        <mat-list-item class="mt-3 no-padding" role="listitem" class="bottom-item">
                            <span class="inline-span">
                                <mat-icon class="gh-icon password-satisfaction-icon"
                                    [ngClass]="{ satisfiedCriterion: getRequirementIsSatisfied('specialCharacter'), unsatisfiedCriterion: !getRequirementIsSatisfied('specialCharacter') }">
                                    {{ getRequirementIsSatisfied('specialCharacter') ? 'check' : 'close' }}</mat-icon>
                            </span>
                            <span class="inline-span ml-3">
                                <mat-card-subtitle
                                    [ngStyle]="{ color: getRequirementIsSatisfiedColor('specialCharacter') }">Special</mat-card-subtitle>
                            </span>
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="waitingOnConfirmation">
    <div class="row confirmation-message-container text-center">
        <div class="col-12 w-100 text-center">
            <mat-card-subtitle class="gh-md-subtitle">
                Please check your email for a confirmation code.
            </mat-card-subtitle>
            <br>
            <mat-card-subtitle class="gh-md-subtitle">
                If you don't see the email, please check your spam folder.
            </mat-card-subtitle>
        </div>
    </div>
    <div class="row confirmation-container">
        <form [formGroup]="confirmationForm">
            <div class="inner-flex-container">
                <!-- <div class="col-12">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Username:</mat-label>
                        <input matInput type="text" formControlName="username">
                    </mat-form-field>
                    <mat-error class="gh-mat-error-simulator"
                        *ngIf="submitIsClicked && confirmationForm.get('username').errors?.required">
                        Required</mat-error>
                </div> -->
                <div class="col-12">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Confirmation Code:</mat-label>
                        <input matInput type="text" formControlName="confirmationCode">
                    </mat-form-field>
                    <mat-error class="gh-mat-error-simulator"
                        *ngIf="submitIsClicked && confirmationForm.get('confirmationCode').errors?.required">
                        Required</mat-error>
                </div>
            </div>
        </form>
    </div>
    <div class="row mt-3 mb-3">
        <div id="resend-code-container" class="col-12">
            <button mat-raised-button class="gh-button gh-stroked-button" (click)="resendCode()">Resend Code</button>
        </div>
    </div>
</div>

<!-- - MEEPLE -->
<div class="peeking-meeple-container">
    <img src="../../../../assets/images/coolStuff/peeking-meeple.png" alt="" *ngIf="!passwordFieldIsFocused">
    <img src="../../../../assets/images/coolStuff/non-peeking-meeple.png" alt="" *ngIf="passwordFieldIsFocused">
</div>