import { Injectable } from '@angular/core'
import { BackendApiS3Service } from './backend-api-s3.service'
import { ImageMultiSizeService } from '../dashboard/dashboard-shared/services/helpers/image-multi-size.service'
import { environment } from 'src/environments/environment'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import gql from 'graphql-tag'
import {
    iContentByPkSkInput,
    iCreateArticleItemInput,
    iCreateCalendarItemsInput,
    iCreateGenericContentItemByPkSkInput,
    iCreateGroupCalendarItemsInput,
    iCreateImageItemsInput,
    iCreateLinkItemsInput,
    iCreateLPItemInput,
    iCreateNotificationItemsInput,
    iCreateReviewItemInput,
    iCreateSESRequestInput,
    iCreateVideoItemInput,
    iDeleteLPInput,
    iUpdateSingleAttributeForContentItemInput,
    iUpdateSingleAttributeForLPInput,
} from '../../../../common/interfaces/ContentInterfaces'
import { Auth } from 'aws-amplify'
import { uploadImageFile, uploadTextFile } from './s3-file-uploader'
import { ArrayHelperService } from '../dashboard/dashboard-shared/services/helpers/array-helper.service'
import { S3ContentHelperService } from '../dashboard/dashboard-shared/services/helpers/s3-content-helper.service'

@Injectable({
    providedIn: 'root',
})
export class BackendAPIContentService {
    constructor(
        private backendApiS3Service: BackendApiS3Service,
        private imageMultiSizeService: ImageMultiSizeService,
        private arrayHelperService: ArrayHelperService,
        private s3ContentHelperService: S3ContentHelperService,
    ) {}

    private appSyncClient = new AWSAppSyncClient({
        url: environment.apiUrl,
        region: 'us-east-1',
        auth: {
            type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
            jwtToken: async () => {
                const token = (await Auth.currentSession()).getIdToken().getJwtToken()
                return token
            },
        },
        disableOffline: true,
    })

    async createGenericContentItemByPkSk(input: iCreateGenericContentItemByPkSkInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createGenericContentItemByPkSk(
                    $input: IGQL_CreateGenericContentItemByPkSkInput!
                ) {
                    createGenericContentItemByPkSk(input: $input) {
                        pk
                        sk
                        data
                    }
                }
            `,
            variables: { input },
        })

        return response.data.createGenericContentItemByPkSk
    }

    async updateSingleAttributeForLP(input: iUpdateSingleAttributeForLPInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation updateSingleAttributeForLP($input: IGQL_UpdateSingleAttributeForLPInput!) {
                    updateSingleAttributeForLP(input: $input) {
                        pkName
                        pkValue
                        skName
                        skValue
                        attributeName
                        attributeValue
                    }
                }
            `,
            variables: { input },
        })

        return response.data.updateSingleAttributeForLP
    }

    async updateSingleAttributeForContentItem(input: iUpdateSingleAttributeForContentItemInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation updateSingleAttributeForContentItem(
                    $input: IGQL_UpdateSingleAttributeForContentItemInput!
                ) {
                    updateSingleAttributeForContentItem(input: $input) {
                        pkName
                        pkValue
                        skName
                        skValue
                        attributeName
                        attributeValue
                    }
                }
            `,
            variables: { input },
        })

        return response.data.updateSingleAttributeForContentItem
    }

    async deleteContentItemByPkSk(input: iContentByPkSkInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation deleteContentItemByPkSk($input: IGQL_ContentByPkSkInput!) {
                    deleteContentItemByPkSk(input: $input) {
                        pk
                        sk
                    }
                }
            `,
            variables: { input },
        })
        return response.data.deleteContentItemByPkSk
    }

    async deleteLP(input: iDeleteLPInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation deleteLP($input: IGQL_DeleteLPInput!) {
                    deleteLP(input: $input) {
                        date
                    }
                }
            `,
            variables: { input },
        })
        return response.data.deleteLP
    }

    async createSESRequest(input: iCreateSESRequestInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createSESRequest($input: IGQL_CreateSESRequestInput!) {
                    createSESRequest(input: $input) {
                        messageText
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createSESRequest
    }

    async createArticleItem(createArticleInput: iCreateArticleItemInput) {
        let textFileRepresentationOfReview = await uploadTextFile(
            createArticleInput.editor,
            'txt',
            'text',
        )

        let input = {
            addedByUID: createArticleInput.addedByUID,
            title: createArticleInput.title,
            editor: textFileRepresentationOfReview,
            textOnlyVersionOfArticle: createArticleInput.textOnlyVersionOfArticle,
            userLevelWhoAdded: createArticleInput.userLevelWhoAdded,
            mainImageFiles: createArticleInput.mainImageFiles,
            intro: createArticleInput.intro,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createArticleItem($input: IGQL_CreateArticleItemInput!) {
                    createArticleItem(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createArticleItem
    }

    async createGroupCalendarItems(createCalendarInput: iCreateGroupCalendarItemsInput) {
        let input = {
            groupId: createCalendarInput.groupId,
            pk: createCalendarInput.pk,
            title: createCalendarInput.title,
            date: createCalendarInput.date,
            end: createCalendarInput.end,
            extendedProps: createCalendarInput.extendedProps,
            prefix: createCalendarInput.prefix,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createGroupCalendarItems($input: IGQL_CreateGroupCalendarItemsInput!) {
                    createGroupCalendarItems(input: $input) {
                        date
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createGroupCalendarItems
    }

    async createCalendarItems(createCalendarInput: iCreateCalendarItemsInput, files) {
        let input = {
            date: createCalendarInput.date,
            end: createCalendarInput.end,
            havenId: createCalendarInput.havenId,
            pk: createCalendarInput.pk,
            prefix: createCalendarInput.prefix,
            title: createCalendarInput.title,
            havenZipCode: createCalendarInput.havenZipCode,
            extendedProps: createCalendarInput.extendedProps,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createCalendarItems($input: IGQL_CreateCalendarItemsInput!) {
                    createCalendarItems(input: $input) {
                        havenId
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createCalendarItems
    }

    async getContentItemByPkSk(pk, sk) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getContentItemByPkSk($pk: String!, $sk: String!) {
                    getContentItemByPkSk(pk: $pk, sk: $sk) {
                        pk
                        sk
                        data
                    }
                }
            `,
            variables: { pk, sk },
            fetchPolicy: 'network-only',
        })

        return response.data.getContentItemByPkSk
    }

    async getCalendarEventById(eventId) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getCalendarEventById($eventId: String!) {
                    getCalendarEventById(eventId: $eventId) {
                        date
                        end
                        havenId
                        pk
                        sk
                        prefix
                        title

                        extendedProps {
                            date
                            description
                            end
                            endTime
                            eventOrganizer
                            eventOrganizerId
                            eventTitle
                            havenTitle
                            mainImageFiles
                            startTime
                            ulid
                            havenTitleText
                            groupTitleText

                            selectedGames {
                                pk
                                title
                                mainImageFiles
                            }
                        }
                    }
                }
            `,
            variables: { eventId },
            fetchPolicy: 'network-only',
        })

        return response.data.getCalendarEventById
    }

    async createImageItems(createGameImageInput: iCreateImageItemsInput) {
        let input = {
            addedByUID: createGameImageInput.addedByUID,
            createdByTitle: createGameImageInput.createdByTitle,
            createdByUID: createGameImageInput.createdByUID,
            gameId: createGameImageInput.gameId,
            gameTitle: createGameImageInput.gameTitle,
            paths: createGameImageInput.paths,
            subtitle: createGameImageInput.subtitle,
            title: createGameImageInput.title,
            userLevelWhoAdded: createGameImageInput.userLevelWhoAdded,

            havenId: createGameImageInput.havenId,
            groupId: createGameImageInput.groupId,
            eventId: createGameImageInput.eventId,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createImageItem($input: IGQL_CreateImageItemsInput!) {
                    createImageItems(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createImageItems
    }

    async createLinkItems(createLinkInput: iCreateLinkItemsInput) {
        let input = {
            addedByUID: createLinkInput.addedByUID,
            paths: createLinkInput.paths,
            title: createLinkInput.title,
            subtitle: createLinkInput.subtitle,
            userLevelWhoAdded: createLinkInput.userLevelWhoAdded,
            link: createLinkInput.link,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createLinkItem($input: IGQL_CreateLinkItemsInput!) {
                    createLinkItems(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createLinkItems
    }

    async createNotificationItems(createNotificationInput: iCreateNotificationItemsInput) {
        let input = {
            addedByTitle: createNotificationInput.addedByTitle,
            addedByUID: createNotificationInput.addedByUID,
            date: createNotificationInput.date,
            eventId: createNotificationInput.eventId,
            eventTitle: createNotificationInput.eventTitle,
            gameId: createNotificationInput.gameId,
            gameMainImageFiles: createNotificationInput.gameMainImageFiles,
            gameTitle: createNotificationInput.gameTitle,
            groupId: createNotificationInput.groupId,
            groupTitle: createNotificationInput.groupTitle,
            havenId: createNotificationInput.havenId,
            havenTitle: createNotificationInput.havenTitle,
            userId: createNotificationInput.userId,
            userImage: createNotificationInput.userImage,

            notificationType: createNotificationInput.notificationType,
            messageIntro: createNotificationInput.messageIntro,
            message: createNotificationInput.message,
            mainImage: createNotificationInput.mainImage,
            paidAd: createNotificationInput.paidAd,
            adIntro: createNotificationInput.adIntro,
            adMessage: createNotificationInput.adMessage,
            eventStart: createNotificationInput.eventStart,
            eventEnd: createNotificationInput.eventEnd,
        }

        try {
            const response = await this.appSyncClient.mutate<any>({
                mutation: gql`
                    mutation createNotificationItem($input: IGQL_CreateNotificationItemsInput!) {
                        createNotificationItem(input: $input) {
                            id
                        }
                    }
                `,
                variables: { input },
            })
            return response.data.createNotificationItem
        } catch (error) {
            console.error('Error creating notification item:', error)
            throw error
        }
    }

    async createVideoItem(createGameVideoInput: iCreateVideoItemInput) {
        let input = {
            addedByUID: createGameVideoInput.addedByUID,
            gameId: createGameVideoInput.gameId,
            gameTitle: createGameVideoInput.gameTitle,
            subtitle: createGameVideoInput.subtitle,
            title: createGameVideoInput.title,
            url: createGameVideoInput.url,
            userLevel: createGameVideoInput.userLevel,
            videoType: createGameVideoInput.videoType,

            havenId: createGameVideoInput.havenId,
            groupId: createGameVideoInput.groupId,
            eventId: createGameVideoInput.eventId,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createVideoItem($input: IGQL_CreateVideoItemInput!) {
                    createVideoItem(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createVideoItem
    }

    async listAllEvents(limit: number) {
        console.log('777 listAllEvents ', limit)

        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllEvents($limit: Int!) {
                    listAllEvents(limit: $limit) {
                        calendarItems {
                            date
                            end
                            extendedProps {
                                date
                                description
                                end
                                endTime
                                eventOrganizer
                                eventOrganizerId
                                eventTitle
                                havenTitle
                                havenZipCode
                                mainImageFiles
                                startTime
                                ulid
                                havenTitleText
                                groupTitleText

                                selectedGames {
                                    big8
                                    corePK
                                    coreSK
                                    mainImageFiles
                                    pk
                                    popularity
                                    sk
                                    title
                                    umv
                                }
                            }
                            havenId
                            havenZipCode
                            pk
                            prefix
                            sk
                            title
                        }
                    }
                }
            `,
            variables: { limit },
            fetchPolicy: 'network-only',
        })

        console.log('Made it to the end of the fetch')

        return response.data.listAllEvents
    }

    async listEventsByZipCode(havenZipCode: string, limit: number) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listEventsByZipCode($havenZipCode: String!, $limit: Int!) {
                    listEventsByZipCode(havenZipCode: $havenZipCode, limit: $limit) {
                        calendarItems {
                            date
                            end
                            extendedProps {
                                date
                                description
                                end
                                endTime
                                eventOrganizer
                                eventOrganizerId
                                eventTitle
                                havenTitle
                                havenZipCode
                                mainImageFiles
                                startTime
                                ulid
                                havenTitleText
                                groupTitleText

                                selectedGames {
                                    big8
                                    corePK
                                    coreSK
                                    mainImageFiles
                                    pk
                                    popularity
                                    sk
                                    title
                                    umv
                                }
                            }
                            havenId
                            havenZipCode
                            pk
                            prefix
                            sk
                            title
                        }
                    }
                }
            `,
            variables: { havenZipCode, limit },
            fetchPolicy: 'network-only',
        })
        return response.data.listEventsByZipCode
    }

    async listContentItemsByPkSk(userId: string, type: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listContentItemsByPkSk(
                    $userId: String!
                    $type: String
                    $limit: Int!
                    $nextToken: String
                ) {
                    listContentItemsByPkSk(
                        userId: $userId
                        type: $type
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        calendarItems {
                            havenId
                            title
                            date
                            end
                            pk
                            sk
                            havenZipCode

                            extendedProps {
                                havenTitle
                                havenZipCode
                                ulid
                                eventTitle
                                description
                                mainImageFiles
                                startTime
                                endTime
                                date
                                eventOrganizer
                                eventOrganizerId
                                end

                                selectedGames {
                                    pk
                                    title
                                    mainImageFiles
                                }
                            }
                        }
                        nextToken
                        context
                        contextIdentity
                        contextIdentityUsername
                        contextArguments
                    }
                }
            `,
            variables: { userId, type, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listContentItemsByPkSk
    }

    async listCalendarEventsById(userId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listCalendarEventsById($userId: String!, $limit: Int!, $nextToken: String) {
                    listCalendarEventsById(userId: $userId, limit: $limit, nextToken: $nextToken) {
                        calendarItems {
                            havenId
                            title
                            date
                            end
                            pk
                            sk

                            extendedProps {
                                havenTitle
                                ulid
                                eventTitle
                                description
                                mainImageFiles
                                date
                                eventOrganizer
                                eventOrganizerId
                                end
                                havenZipCode
                                havenTitleText
                                groupTitleText
                                groupTitle
                                privacy
                                officalStatus

                                selectedGames {
                                    pk
                                    title
                                    mainImageFiles
                                }
                            }
                        }
                        nextToken
                        context
                        contextIdentity
                        contextIdentityUsername
                        contextArguments
                    }
                }
            `,
            variables: { userId, limit, nextToken },
            fetchPolicy: 'network-only',
        })

        return response.data.listCalendarEventsById
    }

    async listLPByDate(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listLPByDate($limit: Int!, $nextToken: String) {
                    listLPByDate(limit: $limit, nextToken: $nextToken) {
                        loggedPlays {
                            gamePlayed
                            id
                            notesToSelf
                            duration
                            expansions
                            date
                            iWon
                            publicNotes
                            pk_UID
                            sk_LPID
                            mainImageFiles
                            uniqueLpGroupingId
                            taggedUsers {
                                title
                                username
                            }
                            selectedNonGHUsers
                            gameId
                        }
                        nextToken
                        context
                        contextIdentity
                        contextIdentityUsername
                        contextArguments
                    }
                }
            `,
            variables: { limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listLPByDate
    }

    // todo issue with gameID being passed up and not game title, which is what is in LP

    async listLPByGameId(gameId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listLPByGameId($gameId: String!, $limit: Int!, $nextToken: String) {
                    listLPByGameId(gameId: $gameId, limit: $limit, nextToken: $nextToken) {
                        loggedPlays {
                            gamePlayed
                            id
                            notesToSelf
                            duration
                            expansions
                            date
                            iWon
                            publicNotes
                            pk_UID
                            sk_LPID
                            mainImageFiles
                            taggedUsers {
                                title
                                username
                            }
                            selectedNonGHUsers
                            gameId
                        }
                        nextToken
                        context
                        contextIdentity
                        contextIdentityUsername
                        contextArguments
                    }
                }
            `,
            variables: { gameId, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listLPByGameId
    }

    // todo issue with gameID being passed up and not game title, which is what is in LP

    async listLPByUserId(userId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listLPByUserId($userId: String!, $limit: Int!, $nextToken: String) {
                    listLPByUserId(userId: $userId, limit: $limit, nextToken: $nextToken) {
                        loggedPlays {
                            gamePlayed
                            id
                            notesToSelf
                            duration
                            expansions
                            date
                            iWon
                            publicNotes
                            pk_UID
                            sk_LPID
                            mainImageFiles
                            taggedUsers {
                                title
                                username
                            }
                            selectedNonGHUsers
                            gameId
                            havenTitle
                            groupTitle
                            eventTitle
                        }
                        nextToken
                        context
                        contextIdentity
                        contextIdentityUsername
                        contextArguments
                    }
                }
            `,
            variables: { userId, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listLPByUserId
    }

    async listLPByHavenTitle(userId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listLPByHavenTitle($userId: String!, $limit: Int!, $nextToken: String) {
                    listLPByHavenTitle(userId: $userId, limit: $limit, nextToken: $nextToken) {
                        loggedPlays {
                            gamePlayed
                            id
                            notesToSelf
                            duration
                            expansions
                            date
                            iWon
                            publicNotes
                            pk_UID
                            sk_LPID
                            mainImageFiles
                            havenTitle
                            userTitle
                            userId
                            gameId
                            havenID
                            uniqueLpGroupingId
                            groupTitle
                        }
                        nextToken
                        context
                        contextIdentity
                        contextIdentityUsername
                        contextArguments
                    }
                }
            `,
            variables: { userId, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listLPByHavenTitle
    }

    async listLPByGroupId(userId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listLPByGroupId($userId: String!, $limit: Int!, $nextToken: String) {
                    listLPByGroupId(userId: $userId, limit: $limit, nextToken: $nextToken) {
                        loggedPlays {
                            gamePlayed
                            id
                            notesToSelf
                            duration
                            expansions
                            date
                            iWon
                            publicNotes
                            pk_UID
                            sk_LPID
                            mainImageFiles
                            havenTitle
                            userTitle
                            userId
                            gameId
                            havenID
                            groupTitle
                            uniqueLpGroupingId
                        }
                        nextToken
                        context
                        contextIdentity
                        contextIdentityUsername
                        contextArguments
                    }
                }
            `,
            variables: { userId, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listLPByGroupId
    }

    async listLPByEventId(eventId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listLPByEventId($eventId: String!, $limit: Int!, $nextToken: String) {
                    listLPByEventId(eventId: $eventId, limit: $limit, nextToken: $nextToken) {
                        loggedPlays {
                            gamePlayed
                            id
                            notesToSelf
                            duration
                            expansions
                            date
                            iWon
                            publicNotes
                            pk_UID
                            sk_LPID
                            mainImageFiles
                            havenTitle
                            userTitle
                            userId
                            gameId
                            havenID
                            uniqueLpGroupingId
                        }
                        nextToken
                        context
                        contextIdentity
                        contextIdentityUsername
                        contextArguments
                    }
                }
            `,
            variables: { eventId, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listLPByEventId
    }

    async createLPItem(createLPInput: iCreateLPItemInput, files) {
        createLPInput.mainImageFiles = null

        if (files && files.length > 0) {
            createLPInput.mainImageFiles = await this.s3ContentHelperService.prepareMainImageFiles(
                files,
            )

            let createItemsInput = {
                addedByUID: createLPInput.userId,
                createdByTitle: createLPInput.userTitle,
                createdByUID: createLPInput.userId,
                gameId: createLPInput.gameId,
                gameTitle: createLPInput.gamePlayed,
                paths: [createLPInput.mainImageFiles],
                subtitle: '',
                title: 'From Logged Play: ' + createLPInput.gamePlayed,
                userLevelWhoAdded: 99,
                havenId: createLPInput.havenID,
                groupId: createLPInput.groupId,
                eventId: createLPInput.eventId,
            }

            this.createImageItems(createItemsInput)
        }

        let taggedUsers = [{ title: createLPInput.userTitle, username: createLPInput.addedbyUID }]
        if (createLPInput.userTitle2)
            taggedUsers.push({ title: createLPInput.userTitle2, username: createLPInput.userId2 })
        if (createLPInput.userTitle3)
            taggedUsers.push({ title: createLPInput.userTitle3, username: createLPInput.userId3 })
        if (createLPInput.userTitle4)
            taggedUsers.push({ title: createLPInput.userTitle4, username: createLPInput.userId4 })
        if (createLPInput.userTitle5)
            taggedUsers.push({ title: createLPInput.userTitle5, username: createLPInput.userId5 })
        if (createLPInput.userTitle6)
            taggedUsers.push({ title: createLPInput.userTitle6, username: createLPInput.userId6 })
        if (createLPInput.userTitle7)
            taggedUsers.push({ title: createLPInput.userTitle7, username: createLPInput.userId7 })
        if (createLPInput.userTitle8)
            taggedUsers.push({ title: createLPInput.userTitle8, username: createLPInput.userId8 })

        let input = {
            addedbyUID: createLPInput.addedbyUID,
            havenTitle: createLPInput.havenTitle,
            havenID: createLPInput.havenID,
            groupTitle: createLPInput.groupTitle,
            groupId: createLPInput.groupId,
            eventTitle: createLPInput.eventTitle,
            eventId: createLPInput.eventId,
            zipCode: createLPInput.zipCode,
            user2ZipCode: createLPInput.user2ZipCode,
            user3ZipCode: createLPInput.user3ZipCode,
            user4ZipCode: createLPInput.user4ZipCode,
            user5ZipCode: createLPInput.user5ZipCode,
            user6ZipCode: createLPInput.user6ZipCode,
            user7ZipCode: createLPInput.user7ZipCode,
            user8ZipCode: createLPInput.user8ZipCode,
            userTitle: createLPInput.userTitle,
            userTitle2: createLPInput.userTitle2,
            userTitle3: createLPInput.userTitle3,
            userTitle4: createLPInput.userTitle4,
            userTitle5: createLPInput.userTitle5,
            userTitle6: createLPInput.userTitle6,
            userTitle7: createLPInput.userTitle7,
            userTitle8: createLPInput.userTitle8,
            userId: createLPInput.userId,
            userId2: createLPInput.userId2,
            userId3: createLPInput.userId3,
            userId4: createLPInput.userId4,
            userId5: createLPInput.userId5,
            userId6: createLPInput.userId6,
            userId7: createLPInput.userId7,
            userId8: createLPInput.userId8,
            gameId: createLPInput.gameId,
            date: createLPInput.date,
            duration: createLPInput.duration,
            expansions: createLPInput.expansions,
            gamePlayed: createLPInput.gamePlayed,
            publicNotes: createLPInput.publicNotes,
            notesToSelf: createLPInput.notesToSelf,
            iWon: createLPInput.iWon,
            mainImageFiles: createLPInput.mainImageFiles,
            taggedUsers: taggedUsers,
            selectedNonGHUsers: createLPInput.selectedNonGHUsers,
            uniqueLpGroupingId: createLPInput.uniqueLpGroupingId,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createLPItem($input: IGQL_CreateLPItemInput!) {
                    createLPItem(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createLPItem
    }

    async createReviewItem(createReviewInput: iCreateReviewItemInput, selectedImage) {
        let s3ResultAfter
        if (selectedImage) {
            const extension = selectedImage.rawImage.name.split('.').pop()
            const s3ObjectUrl = await uploadImageFile(selectedImage.rawImage, extension)
            const correctedUrl = s3ObjectUrl.replace(
                'https://gamehaven-api-dev-appdatabucket-d1uqdueie9h5.s3-accelerate.amazonaws.com/',
                'https://gh-img.s3.amazonaws.com/',
            )

            let s3result = this.imageMultiSizeService.createArrayForAllSizes(
                correctedUrl.replace('.jpg', ''),
            )
            s3ResultAfter = s3result
        }

        let textFileRepresentationOfReview = await uploadTextFile(
            createReviewInput.editor,
            'txt',
            'text',
        )

        let input = {
            addedByUID: createReviewInput.addedByUID,
            big8: createReviewInput.big8,
            gameToReview: createReviewInput.gameToReview,
            havenToReview: createReviewInput.havenToReview,
            rating: createReviewInput.rating,
            reviewImage: s3ResultAfter,
            title: createReviewInput.title,
            editor: textFileRepresentationOfReview,
            textOnlyVersionOfReview: createReviewInput.textOnlyVersionOfReview,
            userLevelWhoAdded: createReviewInput.userLevelWhoAdded,
            createdByUID: createReviewInput.createdByUID,
            createdByTitle: createReviewInput.createdByTitle,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createReviewItem($input: IGQL_CreateReviewItemInput!) {
                    createReviewItem(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createReviewItem
    }

    async listVideosThatNeedAttention(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listVideosThatNeedAttention($limit: Int!, $nextToken: String) {
                    listVideosThatNeedAttention(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        videos {
                            id
                            byLikesPK
                            byLikesSK
                            createdAt
                            updatedAt
                            addedByUID
                            title
                            subtitle
                            url
                            videoType
                        }
                    }
                }
            `,
            variables: { limit, nextToken },
        })
        return response.data.listVideosThatNeedAttention
    }

    async listGameImagesThatNeedAttention(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listGameImagesThatNeedAttention($limit: Int!, $nextToken: String) {
                    listGameImagesThatNeedAttention(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        images {
                            id
                            byLikesPK
                            byLikesSK
                            createdAt
                            updatedAt
                            addedByUID
                            paths
                        }
                    }
                }
            `,
            variables: { limit, nextToken },
        })
        return response.data.listGameImagesThatNeedAttention
    }

    async listImagesByGameID(gameID: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listImagesByGameID($gameID: String!, $limit: Int!, $nextToken: String) {
                    listImagesByGameID(gameID: $gameID, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        images {
                            id
                            addedByUID
                            big8
                            byLikesPK
                            byLikesSK

                            contentByGameByLikesPK
                            havenToReview
                            gameToReview
                            createdAt
                            updatedAt
                            editor
                            textOnlyVersionOfReview
                            paths
                            title
                            needsAttentionPK
                            needsAttentionSK
                            rating
                            userLevelWhoAddedGame
                            reviewImage
                            pk
                            sk
                            data
                            contentByGameByLikesPK
                            contentByGroupByLikesPK
                            contentByHavenByLikesPK
                            contentByUserByLikesPK
                            contentByEventByLikesPK
                        }
                    }
                }
            `,
            variables: { gameID, limit, nextToken },
        })
        return response.data.listImagesByGameID
    }

    async listContentByEventID(eventId: string, type: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listContentByEventID(
                    $eventId: String!
                    $type: String
                    $limit: Int!
                    $nextToken: String
                ) {
                    listContentByEventID(
                        eventId: $eventId
                        type: $type
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        content {
                            id
                            addedByUID
                            big8
                            byLikesPK
                            byLikesSK
                            contentByGameByLikesPK
                            havenToReview
                            gameToReview
                            createdAt
                            updatedAt
                            editor
                            textOnlyVersionOfReview
                            paths
                            title
                            needsAttentionPK
                            needsAttentionSK
                            rating
                            userLevelWhoAddedGame
                            reviewImage
                            pk
                            sk
                            data
                            url
                            contentByGameByLikesPK
                            contentByGroupByLikesPK
                            contentByEventByLikesPK
                            contentByHavenByLikesPK
                            contentByUserByLikesPK
                        }
                    }
                }
            `,
            variables: { eventId, type, limit, nextToken },
        })
        return response.data.listContentByEventID
    }

    async listContentByUserID(userId: string, type: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listContentByUserID(
                    $userId: String!
                    $type: String
                    $limit: Int!
                    $nextToken: String
                ) {
                    listContentByUserID(
                        userId: $userId
                        type: $type
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        content {
                            addedByTitle
                            addedByUID
                            big8
                            big8
                            byLikesSK
                            contentByEventByLikesPK
                            contentByGameByLikesPK
                            contentByGroupByLikesPK
                            contentByHavenByLikesPK
                            contentByUserByLikesPK
                            createdAt
                            createdByUID
                            data
                            date
                            editor
                            eventId
                            eventTitle
                            gameId
                            gameMainImageFiles
                            gameTitle
                            gameToReview
                            groupId
                            groupTitle
                            havenId
                            havenTitle
                            havenToReview
                            notificationType
                            messageIntro
                            message
                            mainImage
                            paidAd

                            adIntro
                            adMessage

                            needsAttentionPK
                            needsAttentionSK
                            paths
                            pk
                            rating
                            reviewImage
                            sk
                            subtitle
                            textOnlyVersionOfReview
                            title
                            updatedAt
                            url
                            userId
                            userImage
                            userLevelWhoAddedGame
                            videoType
                            eventStart
                            eventEnd
                        }
                    }
                }
            `,
            variables: { userId, type, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listContentByUserID
    }

    async listContentByGroupID(groupId: string, type: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listContentByGroupID(
                    $groupId: String!
                    $type: String
                    $limit: Int!
                    $nextToken: String
                ) {
                    listContentByGroupID(
                        groupId: $groupId
                        type: $type
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        content {
                            id
                            addedByUID
                            big8
                            byLikesPK
                            byLikesSK
                            contentByGameByLikesPK
                            havenToReview
                            gameToReview
                            createdAt
                            updatedAt
                            editor
                            textOnlyVersionOfReview
                            paths
                            title
                            needsAttentionPK
                            needsAttentionSK
                            rating
                            userLevelWhoAddedGame
                            reviewImage
                            pk
                            sk
                            data
                            url
                            contentByGameByLikesPK
                            contentByGroupByLikesPK
                            contentByEventByLikesPK
                            contentByHavenByLikesPK
                            contentByUserByLikesPK
                        }
                    }
                }
            `,
            variables: { groupId, type, limit, nextToken },
        })
        return response.data.listContentByGroupID
    }

    async listContentByHavenID(havenId: string, type: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listContentByHavenID(
                    $havenId: String!
                    $type: String
                    $limit: Int!
                    $nextToken: String
                ) {
                    listContentByHavenID(
                        havenId: $havenId
                        type: $type
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        content {
                            addedByUID
                            big8
                            byLikesPK
                            byLikesSK
                            contentByGameByLikesPK
                            createdAt
                            data
                            editor
                            gameToReview
                            havenToReview
                            id
                            needsAttentionPK
                            needsAttentionSK
                            paths
                            pk
                            rating
                            reviewImage
                            sk
                            subtitle
                            textOnlyVersionOfReview
                            title
                            updatedAt
                            url
                            userLevelWhoAddedGame
                        }
                    }
                }
            `,
            variables: { havenId, type, limit, nextToken },
        })
        return response.data.listContentByHavenID
    }

    async listImagesByUserID(userId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listImagesByUserID($userId: String!, $limit: Int!, $nextToken: String) {
                    listImagesByUserID(userId: $userId, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        images {
                            id
                            title
                            subtitle
                            byLikesPK
                            byLikesSK
                            createdAt
                            updatedAt
                            addedByUID
                            paths
                            gameTitle
                            createdByTitle
                            createdByUID
                        }
                    }
                }
            `,
            variables: { userId, limit, nextToken },
        })
        return response.data.listImagesByUserID
    }

    // ****************************************************************************
    // ****************************************************************************
    // ****************************************************************************

    // todo async listImagesByGroupID(userId: string, limit: number, nextToken?) {}
    // todo async listImagesByHavenID(userId: string, limit: number, nextToken?) {}
    // todo: you need to make both GSIs
    //! Whoever has the ability to access delete / untag on Game, Haven, or Group
    //! would be setting that attrubute = null
    //! When the user who uploaaded the item deletes it on their Profile OR Official Haven
    //! then the item and S3 data will be removed and all references to it

    // ****************************************************************************
    // ****************************************************************************
    // ****************************************************************************

    async listReviewsByUserID(userId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listReviewsByUserID($userId: String!, $limit: Int!, $nextToken: String) {
                    listReviewsByUserID(userId: $userId, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        reviews {
                            reviewImage
                            id
                            title
                            createdAt
                            updatedAt
                            addedByUID
                            gameToReview
                            havenToReview
                            reviewImage
                            big8
                            editor
                            textOnlyVersionOfReview
                            rating
                            createdByUID
                        }
                    }
                }
            `,
            variables: { userId, limit, nextToken },
        })
        return response.data.listReviewsByUserID
    }

    async listReviewsByGameTitle(gameId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listReviewsByGameTitle($gameId: String!, $limit: Int!, $nextToken: String) {
                    listReviewsByGameTitle(gameId: $gameId, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        reviews {
                            reviewImage
                            id
                            title
                            createdAt
                            updatedAt
                            addedByUID
                            gameToReview
                            havenToReview
                            reviewImage
                            big8
                            editor
                            textOnlyVersionOfReview
                            rating
                            createdByUID
                        }
                    }
                }
            `,
            variables: { gameId, limit, nextToken },
        })
        return response.data.listReviewsByGameTitle
    }

    async listArticlesByUserID(userId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listArticlesByUserID($userId: String!, $limit: Int!, $nextToken: String) {
                    listArticlesByUserID(userId: $userId, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        articles {
                            id
                            title
                            createdAt
                            updatedAt
                            addedByUID
                            editor
                            textOnlyVersionOfArticle
                            intro
                            mainImageFiles
                        }
                    }
                }
            `,
            variables: { userId, limit, nextToken },
        })
        return response.data.listArticlesByUserID
    }

    async listLinksByUserID(userId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listLinksByUserID($userId: String!, $limit: Int!, $nextToken: String) {
                    listLinksByUserID(userId: $userId, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        links {
                            id
                            byLikesPK
                            byLikesSK
                            createdAt
                            updatedAt
                            addedByUID
                            paths
                            title
                            subtitle
                            paths
                            link
                        }
                    }
                }
            `,
            variables: { userId, limit, nextToken },
        })
        return response.data.listLinksByUserID
    }

    async listOtherVideosByUserID(userId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listOtherVideosByUserID($userId: String!, $limit: Int!, $nextToken: String) {
                    listOtherVideosByUserID(userId: $userId, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        videos {
                            title
                            subtitle
                            byLikesPK
                            byLikesSK
                            createdAt
                            updatedAt
                            addedByUID
                            url
                        }
                    }
                }
            `,
            variables: { userId, limit, nextToken },
        })
        return response.data.listOtherVideosByUserID
    }

    async listOtherVideosByGameID(gameID: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listOtherVideosByGameID($gameID: String!, $limit: Int!, $nextToken: String) {
                    listOtherVideosByGameID(gameID: $gameID, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        videos {
                            title
                            subtitle
                            byLikesPK
                            byLikesSK
                            createdAt
                            updatedAt
                            addedByUID
                            url
                        }
                    }
                }
            `,
            variables: { gameID, limit, nextToken },
        })
        return response.data.listOtherVideosByGameID
    }

    async listAllVideosByUserID(userId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllVideosByUserID($userId: String!, $limit: Int!, $nextToken: String) {
                    listAllVideosByUserID(userId: $userId, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        videos {
                            title
                            subtitle
                            byLikesPK
                            byLikesSK
                            createdAt
                            updatedAt
                            addedByUID
                            url
                            id
                            videoType
                            gameTitle
                        }
                    }
                }
            `,
            variables: { userId, limit, nextToken },
        })
        return response.data.listAllVideosByUserID
    }

    async listAllVideosByTypeByGameID(prefix: string, gameID: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllVideosByTypeByGameID(
                    $prefix: String!
                    $gameID: String!
                    $limit: Int!
                    $nextToken: String
                ) {
                    listAllVideosByTypeByGameID(
                        prefix: $prefix
                        gameID: $gameID
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        videos {
                            title
                            subtitle
                            byLikesPK
                            byLikesSK
                            createdAt
                            updatedAt
                            addedByUID
                            url
                            id
                            videoType
                            gameTitle
                        }
                    }
                }
            `,
            variables: { prefix, gameID, limit, nextToken },
        })
        return response.data.listAllVideosByTypeByGameID
    }

    async listAllVideosByTypeByHavenID(prefix: string, gameID: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllVideosByTypeByGameID(
                    $prefix: String!
                    $gameID: String!
                    $limit: Int!
                    $nextToken: String
                ) {
                    listAllVideosByTypeByGameID(
                        prefix: $prefix
                        gameID: $gameID
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        videos {
                            title
                            subtitle
                            byLikesPK
                            byLikesSK
                            createdAt
                            updatedAt
                            addedByUID
                            url
                            id
                            videoType
                            gameTitle
                        }
                    }
                }
            `,
            variables: { prefix, gameID, limit, nextToken },
        })
        return response.data.listAllVideosByTypeByGameID
    }

    async deleteImageOrVideo(input) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation deleteImageOrVideo($input: IGQL_DeleteImageOrVideoInput!) {
                    deleteImageOrVideo(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        })
        return response.data.deleteImageOrVideo
    }

    async deleteLink(input) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation deleteLink($input: IGQL_DeleteImageOrVideoInput!) {
                    deleteLink(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        })
        return response.data.deleteLink
    }

    async deleteReview(input) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation deleteReview($input: IGQL_DeleteReviewInput!) {
                    deleteReview(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        })
        return response.data.deleteReview
    }

    async deleteArticle(input) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation deleteArticle($input: IGQL_DeleteArticleInput!) {
                    deleteArticle(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        })
        return response.data.deleteArticle
    }
}
