<div class="parent-container mt-2">

    <div class="row mx-0 mb-3" *ngIf="isMobileScreen">

        <div class="col-12 text-right">
            <button mat-raised-button class="gh-button gh-button-primary" (click)="composeMessage(user)">
                Compose Message
            </button>
        </div>

        <div class="col-12">
            <mat-form-field class="gh-form-field">
                <mat-label>Messaging Option</mat-label>
                <mat-select (selectionChange)="setType($event)">
                    <mat-option *ngFor="let option of options" [value]="option.value">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>

    <div class="row mx-0" *ngIf="!isMobileScreen">
        <div class="col-9">
            <mat-form-field class="gh-form-field">
                <mat-label>Messaging Options</mat-label>
                <mat-select (selectionChange)="setType($event)">
                    <mat-option *ngFor="let option of options" [value]="option.value">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-3">
            <button mat-raised-button class="gh-button gh-button-primary" (click)="composeMessage(user)">
                Compose Message
            </button>
        </div>
    </div>


    <div [ngSwitch]="type">

        <!-- * INBOX -->
        <div *ngSwitchDefault="'inbox'">
            <mat-card-title class="gh-sm-title">Inbox</mat-card-title>
            <div class=" mt-2">
                <app-messages-table [_dataSource]="recievedMessagesDataSource"
                    [_displayedColumns]="displayedColumnsReceived" [_messageTableType]="inboxMessageTableType"
                    [_user]="user" [_hasDeleteButtons]="hasDeleteButtons" [_hasArchiveButtons]="hasArchiveButtons"
                    (composeMessageEmitter)="composeMessage($event)"></app-messages-table>
            </div>
        </div>

        <!-- * SENT -->
        <div *ngSwitchCase="'sent'">
            <mat-card-title class="gh-sm-title">Sent Messages</mat-card-title>
            <div class="mt-2">
                <app-messages-table [_dataSource]="sentMessagesDataSource" [_displayedColumns]="displayedColumnsSent"
                    [_messageTableType]="sentMessageTableType" [_user]="user" [_hasDeleteButtons]="hasDeleteButtons"
                    [_hasArchiveButtons]="hasArchiveButtons"
                    (composeMessageEmitter)="composeMessage($event)"></app-messages-table>
            </div>
        </div>

        <!-- * ARCHIVED MESSAGES -->
        <div *ngSwitchCase="'archived'">
            <mat-card-title class="gh-sm-title">Archived Messages</mat-card-title>
            <div class="px-2 mt-2">
                <app-messages-table [_dataSource]="archivedMessagesDataSource"
                    [_displayedColumns]="displayedColumnsArchived" [_messageTableType]="archivedMessageTableType"
                    [_user]="user" [_hasDeleteButtons]="hasDeleteButtons" [_hasArchiveButtons]="hasArchiveButtons"
                    (composeMessageEmitter)="composeMessage($event)"></app-messages-table>

            </div>
        </div>


    </div>
</div>


<!-- * Incoming FRIEND REQUESTS -->
<!-- <div *ngSwitchCase="'requests'">
                <mat-card-title class="gh-sm-title">Incoming Friend Requests</mat-card-title>
                <div class="px-2 mt-2">
                    <app-friend-requests-table [_dataSource]="friendRequestsDataSource"
                        [_displayedColumns]="displayedColumnsIncomingFriendRequests" [_isIncoming]="true" [_user]="user"
                        [_hasDeleteButtons]="hasDeleteButtons"
                        [_hasConfirmationButtons]="hasConfirmationButtons"></app-friend-requests-table>
                </div>
            </div> -->


<!-- * Outgoing FRIEND REQUESTS -->
<!-- <div *ngSwitchCase="'requests'" class="mt-4">
    
                <mat-card-title class="gh-sm-title">Outgoing Friend Requests</mat-card-title>
                <div class="px-2 mt-2">
                    <app-friend-requests-table [_dataSource]="dataSource5"
                        [_displayedColumns]="displayedColumnsOutgoingFriendRequests" [_isIncoming]="false" [_user]="user"
                        [_hasDeleteButtons]="hasDeleteButtons"
                        [_hasConfirmationButtons]="hasConfirmationButtons"></app-friend-requests-table>
                </div>
            </div> -->

<!-- asdffadsadsadsfadfsd -->

<!-- * Incoming EVENT INVITES -->
<!-- <div *ngSwitchCase="'invites'">
    
                <mat-card-title class="gh-sm-title">Incoming invites</mat-card-title>
                <div class="px-2 mt-2">
                    <app-invites-table [_dataSource]="incomingInvitesDataSource"
                        [_displayedColumns]="displayedColumnsIncomingEventInvites" [_isIncoming]="true" [_user]="user"
                        [_hasDeleteButtons]="hasDeleteButtons"
                        [_hasConfirmationButtons]="hasConfirmationButtons"></app-invites-table>
                </div>
            </div> -->

<!-- * Outgoing EVENT INVITES -->
<!-- <div *ngSwitchCase="'invites'" class="mt-4">
                <mat-card-title class="gh-sm-title">Outgoing Invites</mat-card-title>
                <div class="px-2 mt-2">
                    <app-invites-table [_dataSource]="outgoingInvitesDataSource"
                        [_displayedColumns]="displayedColumnsOutgoingEventInvites" [_isIncoming]="false" [_user]="user"
                        [_hasDeleteButtons]="hasDeleteButtons"
                        [_hasConfirmationButtons]="hasConfirmationButtons"></app-invites-table>
                </div>
            </div> -->