<nav class="navbar navbar-dark navbar-expand-lg">
    <div id="mobile-header" *ngIf="isMobileScreen; else branding">
        <a
            id="menu-button"
            *ngIf="showMobileMenuIcon; else showHomeButton"
            (click)="toggleSidebar()"
        >
            <mat-icon>menu</mat-icon>
        </a>

        <ng-template #showHomeButton>
            <a id="menu-button" (click)="goHome()" #header>
                <img
                    id="logo"
                    class=""
                    src="../../../../../../assets/images/coolStuff/logo-redo-no-flag.png"
                    alt=""
                />
            </a>
        </ng-template>

        <!-- <div id="mobile-title-span">
      <mat-card-title class="gh-sm-title header-page-title">{{pageTitle}}</mat-card-title>
    </div> -->

        <span id="mobile-experimental-button">
            <ng-container *ngTemplateOutlet="trendingData"></ng-container>
        </span>

        <span id="mobile-experimental-button">
            <ng-container *ngTemplateOutlet="expirimentalStuff"></ng-container>
        </span>

        <span id="mobile-experimental-button">
            <ng-container *ngTemplateOutlet="friends"></ng-container>
        </span>

        <span id="mobile-messaging-button">
            <ng-container *ngTemplateOutlet="messaging"></ng-container>
        </span>

        <span id="mobile-invites-button">
            <ng-container *ngTemplateOutlet="invites"></ng-container>
        </span>

        <!-- <span id="temp-conventions-button" *ngIf="isGHAdmin">
            <ng-container *ngTemplateOutlet="tempConventions"></ng-container>
        </span> -->

        <span id="mobile-account-button">
            <ng-container *ngTemplateOutlet="userStuff"></ng-container>
        </span>
    </div>

    <ng-template #branding>
        <a class="navbar-brand" (click)="goHome()" #header>
            <img
                id="logo"
                src="../../../../../../assets/images/coolStuff/logo-redo-no-flag.png"
                alt=""
            />
        </a>
        <!-- <a class="navbar-brand" (click)="goHome()" *ngIf="showAlphaBadge" #logo>
      <img id="logo-alpha-badge" src="../../../../../../assets/images/coolStuff/alpha-badge.png" alt="">
    </a> -->
    </ng-template>

    <div *ngIf="!isMobileScreen" class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <!-- <li class="active nav-item">
        <a class="nav-link" href="#">Left links here... <span class="sr-only">(current)</span></a>
      </li> -->
        </ul>

        <form class="form-inline my-2 my-lg-0">
            <!-- <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="my-2 my-sm-0 btn btn-outline-success" type="submit">Search</button> -->

            <!-- TODO FUTURE FEATURE NOTIFICATIONS -->
            <!--  how to style badge?  will it be solved by custom material themes?  -->
            <!-- <span class="mr-5"> 
        <a class="mr-5 ml-3 nav-link" (click)="toggleNotificationPanel()" style="cursor: pointer">
          <mat-icon id="badge" matBadge="{{ notificationCount }}" matBadgePosition="above after"
            class="gh-icon gh-icon-clickable">notifications
          </mat-icon>
        </a>
      </span> -->

            <!--  
            <span class="mr-5">
        <a (click)="togglePickUsernamePanel()" style="cursor: pointer;">
          <mat-icon id="mail-icon" class="gh-icon gh-icon-clickable">person
          </mat-icon>
        </a>
      </span> -->

            <!-- !!!! DARK MODE NEW VERSION!!!! -->
            <!-- <span class="mr-5">
        <mat-slide-toggle (change)="toggleDarkMode($event)" style="color: white">Dark Mode</mat-slide-toggle>
      </span> -->

            <!-- <button (click)="fetchMyNotifications()">Fetch My Notifications</button> -->

            <span class="mr-5">
                <ng-container *ngTemplateOutlet="notifications"></ng-container>
            </span>

            <span class="mr-5">
                <ng-container *ngTemplateOutlet="trendingData"></ng-container>
            </span>

            <!-- !!!! DARK MODE OLD VERSION!!!! -->
            <span class="mr-5">
                <ng-container *ngTemplateOutlet="expirimentalStuff"></ng-container>
            </span>

            <span class="mr-5">
                <ng-container *ngTemplateOutlet="friends"></ng-container>
            </span>

            <span class="mr-5">
                <ng-container *ngTemplateOutlet="messaging"></ng-container>
            </span>

            <span class="mr-5">
                <ng-container *ngTemplateOutlet="invites"></ng-container>
            </span>

            <!-- <span class="mr-5" *ngIf="isGHAdmin">
                <ng-container *ngTemplateOutlet="tempConventions"></ng-container>
            </span> -->

            <span class="mr-5">
                <ng-container *ngTemplateOutlet="userStuff"></ng-container>
            </span>

            <!-- <span class="mr-5">
        <button mat-stroked-button class="with-colored-border gh-stroked-button gh-stroked-button-4" (click)="logOut()">
          LOG OUT
        </button>
      </span> -->

            <!-- commented out below cause not dealing with the animation right now -->

            <!-- <span class="testing-toggles" *ngIf="showTestingToggles"
        [ngClass]="{ opacityZero: hideSecretButtonsForAnimation }"
         [@growInTrigger]="animState" (@growInTrigger.start)="onAnimStart($event)" (@growInTrigger.done)="onAnimDone($event)"> -->

            <span class="testing-toggles" *ngIf="showTestingToggles">
                <button
                    mat-raised-button
                    (click)="openLocationSelector()"
                    class="mr-3 gh-button gh-button-4"
                >
                    Location Selector
                </button>
                <button
                    mat-raised-button
                    (click)="openHavenTestingButtons()"
                    class="mr-3 gh-button gh-button-4"
                >
                    Haven Testin
                </button>
                <button
                    mat-raised-button
                    (click)="openBulkRateGames()"
                    class="mr-3 gh-button gh-button-4"
                >
                    Bulk Rate
                </button>
                <button
                    mat-raised-button
                    (click)="openTournamentBuilderSetup()"
                    class="mr-3 gh-button gh-button-4"
                >
                    Tourn
                </button>
                <button
                    mat-raised-button
                    (click)="openMapTesting()"
                    class="mr-3 gh-button gh-button-4"
                >
                    MAP
                </button>
                <button
                    mat-raised-button
                    (click)="openMapLocationSelectorTextOnly()"
                    class="mr-3 gh-button gh-button-4"
                >
                    LOC
                </button>

                <button
                    mat-raised-button
                    (click)="openEventBuilder()"
                    class="mr-3 gh-button gh-button-4"
                >
                    Event Builder
                </button>

                <mat-slide-toggle
                    (change)="toggleUserIsHaven($event)"
                    class="ml-2 mr-2"
                ></mat-slide-toggle>

                <mat-slide-toggle
                    (change)="toggleShowExpirimentalItems($event)"
                    class="ml-2 mr-2"
                ></mat-slide-toggle>
            </span>

            <span
                *ngIf="currentUser && currentUser.userLevel >= 6"
                #secretButton
                class="secret-button-container ml-5"
                (mousedown)="onSecretButtonMouseDown()"
            >
                <span class="secret-button-inner-container">
                    <img
                        id="lock"
                        [ngClass]="{ keepLockVisible: keepLockVisible }"
                        src="../../../../assets/images/coolStuff/lock-only.png"
                        alt=""
                    />
                    <img
                        #key
                        id="key"
                        [ngClass]="{
                            shouldAnimateOpen: shouldAnimateOpen,
                            shouldAnimateClose: shouldAnimateClose
                        }"
                        src="../../../../assets/images/coolStuff/key-only.png"
                        alt=""
                    />
                </span>
            </span>
        </form>
    </div>
</nav>

<!-- todo FRIENDS -->
<ng-template #friends>
    <div class="experimental-stuff">
        <button id="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>person_add</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="toggleFriendsAndFollowers()">
                <span>Friend Invites</span>
            </button>
        </mat-menu>
    </div>
</ng-template>

<!-- todo notifications -->
<ng-template #notifications>
    <div class="experimental-stuff">
        <button id="menu-button" mat-icon-button (click)="openTheFeed()">
            <a class="gb_d" aria-label="Search Labs" target="_top" role="button" tabindex="0">
                <mat-icon
                    [matBadge]="notificationCount > 0 ? notificationCount : null"
                    class="custom-badge notification-icon"
                    style="color: #fff; font-size: 24px; vertical-align: middle"
                >
                    notifications
                </mat-icon>
            </a>
        </button>
    </div>
</ng-template>

<!-- todo trendingData -->
<ng-template #trendingData>
    <div class="experimental-stuff">
        <button id="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
            <!-- <mat-icon>build</mat-icon> -->
            <a class="gb_d" aria-label="Search Labs" target="_top" role="button" tabindex="0">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path d="M3 3v18h18" />
                    <path fill="#ffffff" d="M18.7 8l-5.1 5.2-2.8-2.7L7 14.3" />
                </svg>
            </a>
        </button>

        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="trendingDataModal()">
                <span>Trending Games</span>
            </button>
        </mat-menu>
    </div>
</ng-template>

<!-- todo EXPERIMENTAL -->
<ng-template #expirimentalStuff>
    <div class="experimental-stuff">
        <button id="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
            <!-- <mat-icon>build</mat-icon> -->
            <a class="gb_d" aria-label="Search Labs" target="_top" role="button" tabindex="0">
                <svg class="gb_h" focusable="false" height="24px" viewBox="0 0 24 24" width="24px">
                    <path
                        fill="#ffffff"
                        d="M22.29,18.37a2,2,0,0,0,0-.24,4.3,4.3,0,0,0-.09-.47c-.05-.15-.11-.31-.17-.46a3.88,3.88,0,0,0-.24-.45l-6.3-8.94V3.64h1.48a.92.92,0,0,0,0-1.84H7.36a.92.92,0,0,0,0,1.84H8.84V7.81L2.55,16.75a2.17,2.17,0,0,0-.24.45,2.85,2.85,0,0,0-.17.46A3.89,3.89,0,0,0,2,18.6c0,.08,0,.16,0,.23A3.8,3.8,0,0,0,2.26,20a3.6,3.6,0,0,0,.59,1,2.5,2.5,0,0,0,.32.33,2.54,2.54,0,0,0,.36.29,3.89,3.89,0,0,0,.4.25,4.28,4.28,0,0,0,.43.19,3.76,3.76,0,0,0,1.22.21H18.72A3.67,3.67,0,0,0,19.94,22l.44-.19a3.64,3.64,0,0,0,1.8-2.28,3.2,3.2,0,0,0,.11-.69,1.69,1.69,0,0,0,0-.23A1.77,1.77,0,0,0,22.29,18.37Zm-1.95.44a.78.78,0,0,1-.05.18l0,.08a.78.78,0,0,0-.05.14,2.09,2.09,0,0,1-.46.64l-.09.08a.88.88,0,0,1-.17.12l-.15.09-.11.06-.25.09a2.33,2.33,0,0,1-.53.07H5.85a1.27,1.27,0,0,1-.28,0,1.93,1.93,0,0,1-.73-.26A.91.91,0,0,1,4.68,20l-.23-.2h0a2.21,2.21,0,0,1-.3-.45l-.06-.12a1.77,1.77,0,0,1-.15-.65,1.88,1.88,0,0,1,.3-1.12l0-.05L10.67,8.5h0V3.64h2.95V8.49h0l6.44,8.92a2.38,2.38,0,0,1,.17.31,2.12,2.12,0,0,1,.14.68A2.58,2.58,0,0,1,20.34,18.81Z"
                    ></path>
                    <path
                        fill="#ffffff"
                        d="M5.66,17.74A.82.82,0,0,0,6.36,19H17.94a.82.82,0,0,0,.7-1.26l-4.1-5.55H9.76Z"
                    ></path>
                </svg>
            </a>
        </button>

        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="testBuySellSwap()">
                <span>Preview Buy/Sell/Swap</span>
                <!-- </button>
            <button mat-menu-item (click)="testPlayNow()">
        <span>Preview Play Now</span> -->
            </button>
            <button mat-menu-item (click)="testGamenight()">
                <span>Preview Gamenight</span>
            </button>
            <button mat-menu-item (click)="testHopper()">
                <span>Preview Hopper</span>
            </button>
        </mat-menu>
    </div>
</ng-template>

<!-- todo INVITES -->
<ng-template #invites>
    <div class="experimental-stuff">
        <button id="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>calendar_today</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="toggleCalendarManagementPanel()">
                <span>Event Invites</span>
            </button>
        </mat-menu>
    </div>
</ng-template>

<!-- <ng-template #tempConventions>
    <a (click)="goToConventionsTempPage()" style="cursor: pointer">
        <mat-icon id="temp-conventions-icon" class="gh-icon gh-icon-clickable">dashboard </mat-icon>
    </a>
</ng-template> -->

<ng-template #messaging>
    <a (click)="toggleMessagingPanel()" style="cursor: pointer">
        <!-- ! badge is a future Feature -->
        <!-- <mat-icon id="mail-icon" [matBadge]="notificationCount" matBadgeColor="primary"
    class="gh-icon gh-icon-clickable">mail
  </mat-icon> -->

        <mat-icon id="mail-icon" class="gh-icon gh-icon-clickable">mail </mat-icon>
    </a>
</ng-template>

<!-- todo AVATAR USER STUFF -->
<ng-template #userStuff>
    <div class="user-stuff">
        <button id="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
            <!-- <mat-icon>account_circle</mat-icon> -->
            <img
                id="avatar-img"
                src="{{ userAvatarImg }}"
                alt=""
                [ngClass]="{ isDesktop: !isMobileScreen }"
            />
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editProfile()">
                <mat-icon>account_circle</mat-icon>
                <span>{{ user.value ? user.value.title : null }}</span>
                <span style="display: inline" *ngIf="isGHStaff" class="white-text">
                    (GH STAFF)
                </span>
                <span style="display: inline" *ngIf="isGHAdmin" class="white-text">
                    (GH ADMIN)
                </span>
                <span style="display: inline" *ngIf="isHaven" class="white-text"> (HAVEN) </span>
            </button>
            <button mat-menu-item [disabled]="true">
                <mat-icon>assignment_ind</mat-icon>
                <span>Level: </span>
                <span>{{ user.value ? user.value.userLevel : null }}</span>
            </button>
            <button mat-menu-item [disabled]="true">
                <mat-icon>tag_faces</mat-icon>
                <span>Points: </span>
                <span>{{ user.value ? user.value.GH_Points : null }}</span>
            </button>
            <button mat-menu-item (click)="manageAccountSettings()">
                <mat-icon>settings</mat-icon>
                <span>Account Settings</span>
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>
    </div>
</ng-template>
