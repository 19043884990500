<div class="parent-container">
    <div class="mx-0 mt-4 row" *ngIf="!(isLoading | async)">
        <div class="col-12" *ngIf="selectedEntity$ | async">
            <app-profile-avatar-card
                [_isAuthenticated]="isAuthenticated.value"
                [_selectedEntity]="selectedEntity.value"
                [_radarData]="radarData"
                [_friendRequestData]="friendRequestData.value"
                [_selectedEntityBio]="selectedEntity.value.bio"
                [_isFriend]="selectedUserIsFriend"
                [_isCurrentUsersProfile]="isCurrentUsersProfile"
                [_userIsAdmin]="userIsAdmin"
                (onContactButtonClicked)="contactButtonClicked()"
            >
            </app-profile-avatar-card>
        </div>
    </div>

    <div class="mx-0 mt-3 row">
        <!-- <h3>userClickedInLast5sec: {{userIsActive}}</h3>
        <h3>-----</h3>
        <h3>isTabFocused: {{isTabFocused}}</h3>
        <h3>-----</h3>
        <h3>userBeenScrolling1sec: {{isScrolling }}</h3> -->

        <div class="col-sm-12 col-12 col-lg-6 col-md-12 games-i-love">
            <mat-card class="mat-elevation-z6">
                <div class="gh-card-header-row has-button row">
                    <div class="col-12">
                        <span class="inline-span">
                            <mat-card-title class="gh-sm-title">
                                {{
                                    isAuthenticated.value
                                        ? gamesILoveLoggedIn
                                        : gamesILovedNotLoggedIn
                                }}</mat-card-title
                            >
                        </span>
                        <span *ngIf="isCurrentUsersProfile" class="gh-header-row-item-right mr-1">
                            <mat-icon
                                class="gh-edit-icon gh-icon gh-icon-clickable"
                                matTooltip="Edit"
                                matTooltipPosition="above"
                                (click)="editGamesILove()"
                            >
                                edit
                            </mat-icon>
                        </span>
                    </div>
                    <div class="col-12">
                        <mat-divider></mat-divider>
                    </div>
                    <div *ngIf="isMobileScreen" class="col-12">
                        <app-gh-swiper
                            *ngIf="gamesILove.length != 0"
                            [_items]="gamesILove"
                            [_numberOfTiles]="2"
                            [_imgMaxSizePx]="128"
                            (_itemClickedEmitter)="onGameDetailSelected($event)"
                        >
                        </app-gh-swiper>
                    </div>
                    <div *ngIf="!isMobileScreen" class="col-12">
                        <app-gh-swiper
                            *ngIf="gamesILove.length != 0"
                            [_items]="gamesILove"
                            [_numberOfTiles]="4"
                            [_imgMaxSizePx]="100"
                            (_itemClickedEmitter)="onGameDetailSelected($event)"
                        >
                        </app-gh-swiper>
                    </div>
                </div>
            </mat-card>
        </div>

        <div class="looking-to-play-col col-sm-12 col-12 col-lg-6 col-md-12">
            <mat-card class="mat-elevation-z6">
                <div class="gh-card-header-row has-button row">
                    <div class="col-12">
                        <span class="inline-span">
                            <!-- <mat-card-title class="gh-sm-title">I want to play:</mat-card-title> -->
                            <mat-card-title class="gh-sm-title">
                                {{
                                    isAuthenticated.value
                                        ? gamesIWantToPlayLoggedIn
                                        : gamesIWantToPlayNotLoggedIn
                                }}</mat-card-title
                            >
                        </span>
                        <span *ngIf="isCurrentUsersProfile" class="gh-header-row-item-right mr-1">
                            <mat-icon
                                class="gh-edit-icon gh-icon gh-icon-clickable"
                                matTooltip="Edit"
                                matTooltipPosition="above"
                                (click)="editLookingToPlay()"
                            >
                                edit
                            </mat-icon>
                        </span>
                    </div>
                    <div class="col-12">
                        <mat-divider></mat-divider>
                    </div>
                    <div *ngIf="isMobileScreen" class="col-12">
                        <app-gh-swiper
                            [_items]="lookingToPlay"
                            [_imgMaxSizePx]="128"
                            [_numberOfTiles]="2"
                            (_itemClickedEmitter)="onGameDetailSelected($event)"
                        ></app-gh-swiper>
                    </div>
                    <div *ngIf="!isMobileScreen" class="col-12">
                        <app-gh-swiper
                            [_items]="lookingToPlay"
                            [_imgMaxSizePx]="128"
                            [_numberOfTiles]="4"
                            (_itemClickedEmitter)="onGameDetailSelected($event)"
                        ></app-gh-swiper>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="mx-0 mt-3 mb-3 row">
        <div class="col-sm-12 col-12 col-lg-6 col-md-12">
            <mat-card class="mat-elevation-z6">
                <div class="gh-card-header-row has-button row">
                    <div class="col-12">
                        <span class="inline-span">
                            <mat-card-title class="gh-sm-title">My Recent Plays:</mat-card-title>
                        </span>

                        <span class="inline-span inline-span-align-right" *ngIf="!isMobileScreen">
                            <!-- something here? -->
                        </span>
                    </div>
                    <div class="col-12">
                        <mat-divider></mat-divider>
                    </div>
                </div>
                <!-- TOdo Add input here? -->
                <div class="content-row row">
                    <div class="w-100 col-12">
                        <!-- ! THIS NEEDS TO PASS IN THE SELECTED USER IF THIS IS DIFFERENT FROM THE USER -->
                        <!-- ALSO, REMOVE THE INITlOGGEDpLAYS METHOD IF THIS IS THE RIGHT WAY TO DO IT. -->

                        <app-profile-recent-logged-plays
                            [_selectedEntity]="this.selectedEntity.value"
                        >
                        </app-profile-recent-logged-plays>

                        <!-- <app-profile-recent-activity [_selectedEntity]="selectedEntity" [_isAuthenticated]="isAuthenticated.value" [_statusUpdates]="statusUpdates">
                        </app-profile-recent-activity> -->
                    </div>
                </div>
            </mat-card>
        </div>

        <div class="col-sm-12 col-12 col-lg-6 col-md-12 radar-mini-col">
            <div class="row" *ngIf="!isViewingFriend">
                <div class="col-sm-12 col-12 col-lg-12 col-md-12">
                    <ng-container *ngTemplateOutlet="myGamePreferencesCard"></ng-container>
                </div>

                <!-- <div class="col-sm-12 col-12 col-lg-12 col-md-12"
                    [ngStyle]="{ paddingTop: isMobileScreen ? '1rem' : '5rem' }">
                </div> -->

                <!-- TODO: add container here needs to be reimplemented!-->

                <!-- <h1>Distance: Should be 13.3km ~~~~~~~~~~~~~~~~~~~~~~~~~~</h1>
                    <h1>Distance CHANGED: Should be 13.3km</h1>

                    <h2>{{distance | number : '1.2-2'}} meters</h2> -->
                <!-- 
                    <h2>----------</h2>
                    <h3>49/48 to 48.9/47.9</h3>
                    <h2 style="color: green; font-weight: 800;">Distance: {{distanceKm | number : '1.1-1'}} km</h2> -->

                <!-- <app-banner-ad [_bannerSize]="bannerSize" [_multiplier]="0.75" [_bannerLocation]="bannerLocation"
                        [_specificAd]="specificAds" [_isIFrame]="specificAdIsIFrame"></app-banner-ad> -->

                <!-- <div class="row">
                        <div class="col-6 col-md-12">
                            <app-banner-ad [_inputData]="bannerAdInputDataSpecific" [_sourceOfInput]="'app-profile'">
                            </app-banner-ad>
                        </div>
                        <div class="col-6 col-md-12">
                            <app-banner-ad *ngIf="isMobileScreen" [_inputData]="bannerAdInputDataSpecific"
                                [_sourceOfInput]="'app-profile'">
                            </app-banner-ad>
                        </div>
                    </div> -->
            </div>

            <div class="row" *ngIf="isViewingFriend">
                <div class="col-12">
                    <ng-container *ngTemplateOutlet="myGamePreferencesCard"></ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loadingSpinner>
    <div class="gh-loading-container">
        <app-gh-loading-spinner></app-gh-loading-spinner>
    </div>
</ng-template>

<ng-template #myGamePreferencesCard>
    <mat-card class="mat-elevation-z6 radar-mini-card">
        <div class="gh-card-header-row has-button row">
            <div class="col-12">
                <span class="inline-span" (click)="viewFullRadarChart()">
                    <mat-card-title class="gh-sm-title">My Game Preferences:</mat-card-title>
                </span>
                <span class="inline-span inline-span-align-right" *ngIf="!isMobileScreen">
                    <!-- <button mat-raised-button class="gh-button gh-button-secondary"
                        (click)="viewFullRadarChart()">Detail View</button> -->
                    <!-- <mat-icon (click)="viewFullRadarChart()" *ngIf="isCurrentUsersProfile"
                        class="gh-icon gh-icon-clickable gh-icon-edit">edit</mat-icon> -->
                </span>
            </div>
            <div class="col-12">
                <mat-divider></mat-divider>
            </div>
        </div>

        <div class="content-row row">
            <app-gh-profile-radar-chart-mini
                #miniRadarChart
                (radarChartResultEmitter)="logEmittedResults($event)"
                [_miniRadarData]="radarData"
                [_type]="radarChartType"
                [_selectedItem]="selectedEntity.value"
                [_showMatchPercentage]="!isCurrentUsersProfile"
                [_isCurrentUsersProfile]="isCurrentUsersProfile"
                [_overlayType]="radarOverlayType"
                [_editButtonShows]="false"
                [_uniqueAttribute]="'username'"
                class="mini-radar-chart"
            >
            </app-gh-profile-radar-chart-mini>
        </div>
    </mat-card>
</ng-template>
