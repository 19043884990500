import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { BackendAPIGroupsService } from 'src/app/backend-api-services/backend-api-groups.service'
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { GroupsService } from 'src/app/dashboard/dashboard-shared/services/groups/groups.service'
import { ArrayHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/array-helper.service'
import { RadarChartOverlayTypeEnums, RadarChartTypeEnums } from 'src/app/dashboard/enums/radar-chart-enums'
import { DELETE_CONFIRMATION_PASSWORD } from 'src/app/dashboard/pages/games/pages/games-dashboard/md-components/md-game-detail/md-game-detail.component'
import { SignUpComponent } from 'src/app/shared/components/sign-up/sign-up.component'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { StringHelper } from '../../../../../../../../../../common/helpers/string-helper'
import { AddOrEditGamingGroupTempComponent } from '../../../../groups/add-or-edit-gaming-group-temp/add-or-edit-gaming-group-temp.component'
import { ConfirmActionComponent } from '../../../../modals/confirm-action/confirm-action.component'
import { BulkRateGamesComponent } from '../../../../ratings/bulk-rate-games/bulk-rate-games.component'
import { BackendApiGroupTrackingService } from 'src/app/backend-api-services/backend-api-group-tracking.service'
import { TimingHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/timing-helper.service'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS, ONBOARDING_USER_LEVEL_THRESHOLD } from 'src/app/app.constants'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'
import { BackendApiUserTrackingService } from 'src/app/backend-api-services/backend-api-user-tracking.service'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { NewUserComponent } from 'src/app/dashboard/components/new-user/new-user.component'
import { ProfileService } from 'src/app/dashboard/dashboard-shared/services/profile/profile.service'
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { EditMyProfileComponent } from '../edit-my-profile/edit-my-profile.component'
import { FullSizedImageViewComponent } from '../../../../media/full-sized-image-view/full-sized-image-view.component'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-profile-avatar-card',
    templateUrl: './profile-avatar-card.component.html',
    styleUrls: ['./profile-avatar-card.component.scss'],
})
export class ProfileAvatarCardComponent implements OnInit {

    onboardingUserLevelThreshold = ONBOARDING_USER_LEVEL_THRESHOLD

    panelOpen: boolean = false

    togglePanel() {
        this.panelOpen = !this.panelOpen
    }

    @Output() onContactButtonClicked = new EventEmitter()

    isAuthenticated = false
    @Input() set _isAuthenticated(isAuthenticated) {
        this.isAuthenticated = isAuthenticated
    }

    isGroup = false
    @Input() set _isGroup(isGroup) {
        this.isGroup = isGroup
    }

    selectedGroup
    @Input() set _selectedGroup(selectedGroup) {
        this.selectedGroup = selectedGroup
        this.checkToSeeIfGroupIsInMyGroups()
    }

    isCurrentUsersProfile = false
    @Input() set _isCurrentUsersProfile(isCurrentUsersProfile) {
        this.isCurrentUsersProfile = isCurrentUsersProfile
    }
    joinDate: Date;
    selectedEntity: any;
    selectedEntityImg: string;
    selectedEntityImgFullSized: string;

    @Input() set _selectedEntity(selectedEntity: any) {
        console.log('777 selectedEntity: BAD', selectedEntity); /// Wrong Here

        this.selectedEntity = selectedEntity;
        this.selectedEntity.bio = selectedEntity.bio;
        this.joinDate = this.convertAWSDateTime(selectedEntity.createdAt);

        this.timingHelperService.delay(500).then(() => {
            console.log('Processed selectedEntity:', selectedEntity);

            if (selectedEntity.userImage) {
                selectedEntity.mainImageFiles = selectedEntity.userImage;
            }

            this.selectedEntityImg = selectedEntity.mainImageFiles && selectedEntity.mainImageFiles[0]
                ? selectedEntity.mainImageFiles[0]
                : window.location.origin + '/assets/images/defaults/profile-placeholder.png';

            this.selectedEntityImgFullSized = selectedEntity.mainImageFiles && selectedEntity.mainImageFiles[2]
                ? selectedEntity.mainImageFiles[2]
                : window.location.origin + '/assets/images/defaults/profile-placeholder.png';

            console.log('selectedEntityImg:', this.selectedEntityImg);
        });
    }

    private convertAWSDateTime(awsDateTime: string): Date {
        // Assuming AWSDateTime is in ISO format
        return new Date(awsDateTime);
    }


    truncatedBio
    selectedEntityBio = null
    @Input() set _selectedEntityBio(selectedEntityBio) {
        this.selectedEntityBio = selectedEntityBio ? selectedEntityBio : ''
        this.truncatedBio = StringHelper.truncateString(this.selectedEntityBio, 100)
    }

    contactButtonText = 'Contact'
    @Input() set _contactButtonText(contactButtonText: string) {
        if (contactButtonText) {
            this.contactButtonText = contactButtonText
        }
    }

    isFriend = false
    @Input() set _isFriend(isFriend: boolean) {
        this.isFriend = isFriend
    }

    friendRequestData = new BehaviorSubject(null)
    friendRequestData$ = this.friendRequestData.asObservable()
    @Input() set _friendRequestData(friendRequestData: boolean) {
        this.friendRequestData.next(friendRequestData)
    }

    userIsGroupAdmin
    @Input() set _userIsGroupAdmin(userIsGroupAdmin: boolean) {
        this.userIsGroupAdmin = userIsGroupAdmin
    }

    radarData = new BehaviorSubject(null)
    radarData$ = this.radarData.asObservable()
    radarOverlayType = RadarChartOverlayTypeEnums.myAttributes
    radarChartType = RadarChartTypeEnums.profile
    @Input() set _radarData(radarData) {
        this.radarData.next(radarData)
    }

    @Output() shouldCloseParentModal = new EventEmitter()

    @Output() refreshParent = new EventEmitter()


    constructor(
        private screenSizeService: ScreenSizeService,
        private dialog: MatDialog,
        private backendApiUsersService: BackendAPIUsersService,
        private arrayHelperService: ArrayHelperService,
        private backendApiMessageService: BackendAPIMessageService,
        private groupsService: GroupsService,
        private backendApiGroupsService: BackendAPIGroupsService,
        private backendApiGroupTrackingService: BackendApiGroupTrackingService,
        private backendAPIUsersService: BackendAPIUsersService,
        private snackbar: MatSnackBar,
        private snackbarService: SnackbarService,

        private usersService: UsersService,
        private backendAPIUserTrackingService: BackendApiUserTrackingService,
        private timingHelperService: TimingHelperService,
        private backendApiContentService: BackendAPIContentService,
        private profileService: ProfileService
    ) { }


    myFriends = new BehaviorSubject(null)
    myFriends$ = this.myFriends.asObservable()


    isMobileScreen = false
    bioPanelOpened = false
    userLevel = 1
    currentUser
    myGroups
    isGHAdmin

    isMobileScreenIsKnown = false

    async ngOnInit() {

        this.isCurrentUsersProfile = this.profileService.getIsCurrentUsersProfile()

        await this.backendApiUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((currentUser) => {
                if (currentUser) {
                    this.currentUser = currentUser
                    this.userLevel = currentUser.userLevel // comment this out to force button to be there for testing
                    this.isGHAdmin = this.userLevel > 6
                }
            })

        await this.groupsService.myGroups$
            .pipe(untilDestroyed(this))
            .subscribe(async (myGroups) => {
                if (myGroups) {
                    this.myGroups = myGroups
                }
            })

        this.usersService.myFriends$
            .pipe(untilDestroyed(this))
            .subscribe((myFriends) => {
                this.myFriends.next(myFriends)
            })


        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
                this.isMobileScreenIsKnown = true;
            })

        this.checkToSeeIfGroupIsInMyGroups()
    }

    // ************************************************* GROUPS ******************************************************
    //! ************************************************ GROUPS ******************************************************
    // ************************************************* GROUPS ******************************************************

    isInMyGroups
    checkToSeeIfGroupIsInMyGroups() {
        if (this.isGroup) {
            this.isInMyGroups = false
            const myArr = this.myGroups
            const myString = this.selectedGroup.pk

            for (const obj of myArr) {
                for (const key in obj) {
                    if (obj.hasOwnProperty(key) && obj[key] === myString) {
                        this.isInMyGroups = true
                    }
                }
            }
        }
    }

    editGroup() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Update Group',
                component: AddOrEditGamingGroupTempComponent,
                inputData: {
                    groupToEdit: this.selectedGroup,
                    isGHAdmin: this.currentUser,
                    isEditing: true,
                    // groupToEditMainImage: this.mainImage,
                },
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Update',
                allowParentClose: true,
            },
        })
        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.refreshParent.emit()
            })
    }

    async joinGroup() {
        let input = {
            groupId: this.selectedGroup.coreSK.replace('#', ''),
            UID: this.currentUser.username,
            type: 'myFavorite',
            title: this.selectedGroup.title,
            userTitle: this.currentUser.title,
            userState: this.currentUser.state,
            userZipCode: this.currentUser.zipCode,
            // mainImageFiles: this.user.mainImageFiles && this.user.mainImageFiles.length > 0 ? this.user.mainImageFiles : undefined
        }

        let addToMyGroupsResult = await this.backendApiGroupsService.addToMyGroups(input)
        this.isInMyGroups = true

        this.timingHelperService.delay(1000).then(() => {
            // not sure if this delay is needed, leaving it here anyways for now
            this.groupsService.initCoreGroupData()
            this.groupsService.initMyGroups(this.currentUser.username)
        })

    }





    async leaveGroup() {
        let input = {
            groupId: this.selectedGroup.pk,
            userId: this.currentUser.username,
            type: 'myFavorite',
        }

        await this.backendApiGroupsService.removeFromMyGroups(input)
        this.isInMyGroups = false

        this.timingHelperService.delay(1000).then(() => {
            // not sure if this delay is needed, leaving it here anyways for now
            this.groupsService.initCoreGroupData()
            this.groupsService.initMyGroups(this.currentUser.username)
        })
    }








    async removeAllUsersFromGroup() {
        let followersFound = await this.backendApiGroupsService.listFollowersByGroupId(
            this.selectedGroup.pk,
            50,
        )

        for (let sk of followersFound.followers) {
            let cleavedSK = sk.sk_TYPE_UID_ULID.replace('#myFavorite#', '')

            let input = {
                groupId: this.selectedGroup.pk,
                userId: cleavedSK,
                type: 'myFavorite',
            }

            await this.backendApiGroupsService.removeFromMyGroups(input)
        }
    }

    async removeAllEventsForGroup() {


        let eventsToBeDeleted = await this.backendApiContentService.listCalendarEventsById(this.selectedGroup.pk, 200)
        for (let eventId of eventsToBeDeleted.calendarItems) {
            let attendees = await this.backendApiContentService.listContentItemsByPkSk(
                eventId.pk,
                'e',
                100,
            )



            for (let event of attendees.calendarItems) {
                let input = {
                    pk: event.pk,
                    sk: event.sk,
                }
                this.backendApiContentService.deleteContentItemByPkSk(input)
            }
        }
    }

    async removeUserFromGroup() {
        alert('tryingremove user ')

        let input = {
            groupId: this.selectedGroup.id,
            userId: this.currentUser.username,
            type: 'myFavorite',
        }

        await this.backendApiGroupsService.removeFromMyGroups(input)

        this.isInMyGroups = false
    }

    async deleteGroup() {
        let inputData = {
            message: 'Permanently delete: ' + this.selectedGroup.title + '?',
            submessage: 'All game data and references will be deleted!',
            isDeletion: true,
            hasPassword: true,
        }
        let dialogRefFoo = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Are you sure?',
                component: ConfirmActionComponent,
                inputData: inputData,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        dialogRefFoo
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                if (data && data.toLowerCase().trim() == DELETE_CONFIRMATION_PASSWORD) {
                    this.removeAllUsersFromGroup() // todo this only removes the followers, not the manager
                    this.removeAllEventsForGroup()


                    let username = this.currentUser.username

                    let myGroupsResultToFormArray =
                        await this.backendApiGroupTrackingService.listMyGroups(username, 49)

                    this.backendApiGroupTrackingService

                    let input = {
                        groupId: this.selectedGroup.pk,
                        userId: this.currentUser.username,
                        type: 'gr',
                    }

                    await this.backendApiGroupsService.removeFromMyGroups(input)

                    // todo need to remove the group from MYGROUPS here!!!

                    this.currentUser.groupsIManage = this.arrayHelperService.removeFromArray(
                        this.currentUser.groupsIManage,
                        this.selectedGroup.id,
                        false,
                    )

                    this.backendApiGroupsService
                        .deleteGroup({ id: this.selectedGroup.pk })
                        .then((result) => {
                            this.snackbarService.openSuccessSnackBar(
                                'Deleted ' + this.selectedGroup.title + '!', undefined)
                        })
                    this.timingHelperService.delay(1000).then(() => {
                        // not sure if this delay is needed, leaving it here anyways for now
                        this.groupsService.initCoreGroupData()
                        this.groupsService.initMyGroups(this.currentUser.username)
                        this.shouldCloseParentModal.emit()

                    })
                } else {
                    alert('Wrong Confirmation Code')
                }
            })
    }














    // ************************************************* FRIENDS ******************************************************
    //! ************************************************ FRIENDS ******************************************************
    // ************************************************* FRIENDS ******************************************************




    async unFriend() {
        let deleteInput1pk = this.currentUser.username
        let deleteInput1sk = '#friendOf#' + this.selectedEntity.username

        this.backendAPIUserTrackingService.deleteUserTrackingItem(deleteInput1pk, deleteInput1sk)

        let deleteInput42pk = this.selectedEntity.username
        let deleteInput42sk = '#friendOf#' + this.currentUser.username

        this.backendAPIUserTrackingService.deleteUserTrackingItem(deleteInput42pk, deleteInput42sk)

        this.timingHelperService.delay(1000).then(() => {
            this.usersService.initMyFriends()
            this.usersService.initCoreUserData()
            this.isFriend = false
        })






        // let currentUsersListOfFriends = []
        // if (this.currentUser.listOfFriends) {
        //     currentUsersListOfFriends = JSON.parse(this.currentUser.listOfFriends)
        // }

        // currentUsersListOfFriends = this.arrayHelperService.removeFromArray(
        //     currentUsersListOfFriends,
        //     this.selectedEntity.username,
        //     false,
        // )

        // let input = {
        //     pkName: 'pk',
        //     pkValue: this.currentUser.username,
        //     skName: 'sk',
        //     skValue: `BD#${this.currentUser.username}`,
        //     attributeName: 'listOfFriends',
        //     attributeValue: JSON.stringify(currentUsersListOfFriends),
        // }
        // await this.backendApiUsersService.updateSingleAttributeForUser(input)

        // this.backendApiUsersService.initCurrentUser()

        // let otherUserData = await this.backendApiUsersService.getUserByTitle(
        //     this.selectedEntity.title,
        // )

        // let currentUsersListOfFriends2 = []
        // if (otherUserData.listOfFriends) {
        //     currentUsersListOfFriends2 = JSON.parse(otherUserData.listOfFriends)
        // }

        // currentUsersListOfFriends2 = this.arrayHelperService.removeFromArray(
        //     currentUsersListOfFriends2,
        //     this.currentUser.username,
        //     false,
        // )

        // // currentUsersListOfFriends2.push(this.selectedEntity.pk_UID)

        // let input2 = {
        //     pkName: 'pk',
        //     pkValue: otherUserData.username,
        //     skName: 'sk',
        //     skValue: `BD#${otherUserData.username}`,
        //     attributeName: 'listOfFriends',
        //     attributeValue: JSON.stringify(currentUsersListOfFriends2),
        // }
        // await this.backendApiUsersService.updateSingleAttributeForUser(input2)



    }








    createFriendRequest() {
        let input = {
            type: 'friendRequest',
            createdByUID: this.currentUser.username,
            sentToUID: this.selectedEntity.username,
            sentFromTitle: this.currentUser.title,
            sentToTitle: this.selectedEntity.title,
        }
        this.backendApiMessageService.createRequestItem(input)

        this.friendRequestData.next({
            friendReqeustOutToSelectedUser: true,
            selectedUserIsReqeustingIFriendThem: null,
        })
    }

    async cancelFriendRequest() {
        let createdSk_type_ULID = '#FriendRequestOutgoing#' + this.selectedEntity.username
        let createdSk_Incoming = '#FriendRequestOutgoing#' + this.currentUser.username

        let messageInput = { pk: this.currentUser.username, sk: createdSk_type_ULID }

        await this.backendApiMessageService.deleteMessage(messageInput)
        // other user remove incoming
        let cancelIncomingInput = {
            pk: this.selectedEntity.username,
            sk: createdSk_Incoming.replace('Outgoing', 'Incoming'),
        }

        await this.backendApiMessageService.deleteMessage(cancelIncomingInput)

        this.friendRequestData.next({
            friendReqeustOutToSelectedUser: null,
            selectedUserIsReqeustingIFriendThem: null,
        })
    }


    async declineFriendRequest() {
        //! swap the orientation of these to reflect the other person peforming the click.

        let createdSk_type_ULID = '#FriendRequestOutgoing#' + this.currentUser.username
        let createdSk_Incoming = '#FriendRequestOutgoing#' + this.selectedEntity.username

        let messageInput = { pk: this.selectedEntity.username, sk: createdSk_type_ULID }

        await this.backendApiMessageService.deleteMessage(messageInput)
        // other user remove incoming
        let cancelIncomingInput = {
            pk: this.currentUser.username,
            sk: createdSk_Incoming.replace('Outgoing', 'Incoming'),
        }

        await this.backendApiMessageService.deleteMessage(cancelIncomingInput)

        this.friendRequestData.next({
            friendReqeustOutToSelectedUser: null,
            selectedUserIsReqeustingIFriendThem: null,
        })
    }

    // ! this is outdated, if we use followers then it should be pulled from bulk data
    unfollowFriend() {
        if (!this.isCurrentUsersProfile) {
            let currentUsersListOfFollowers = []
            if (this.currentUser.listOfFollowers) {
                currentUsersListOfFollowers = JSON.parse(this.currentUser.listOfFollowers)
            }

            currentUsersListOfFollowers = this.arrayHelperService.removeFromArray(
                currentUsersListOfFollowers,
                this.selectedEntity.username,
                false,
            )

            let input = {
                pkName: 'pk',
                pkValue: this.currentUser.username,
                skName: 'sk',
                skValue: `BD#${this.currentUser.username}`,
                attributeName: 'listOfFollowers',
                attributeValue: JSON.stringify(currentUsersListOfFollowers),
            }

            this.backendApiUsersService.updateSingleAttributeForUser(input)
            this.backendApiUsersService.initCurrentUser()
        }
    }


    async acceptFriendRequest() { //! WORKING

        let input = {
            userId: this.currentUser.username,
            targetId: this.selectedEntity.username,
            type: 'friendOf'
        }

        this.backendAPIUserTrackingService.createUserTrackingItem(input)

        let input42 = {
            userId: this.selectedEntity.username,
            targetId: this.currentUser.username,
            type: 'friendOf'
        }

        this.backendAPIUserTrackingService.createUserTrackingItem(input42)

        this.backendAPIUsersService.initCurrentUser()


        // MESSAGE CLEANUP
        let createdSk_type_ULID = '#FriendRequestIncoming#' + this.selectedEntity.username

        let removeInvitationInput = { pk: this.currentUser.username, sk: createdSk_type_ULID }
        await this.backendApiMessageService.deleteMessage(removeInvitationInput)

        let createdSk_type_ULID2 = '#FriendRequestOutgoing#' + this.currentUser.username

        let removeInvitationInput2 = { pk: this.selectedEntity.username, sk: createdSk_type_ULID2 }
        await this.backendApiMessageService.deleteMessage(removeInvitationInput2)

        this.friendRequestData.next({
            friendReqeustOutToSelectedUser: null,
            selectedUserIsReqeustingIFriendThem: null,
        })
    }

    contactEntity() {
        this.onContactButtonClicked.emit()
    }

    async followUser() {
        if (!this.isCurrentUsersProfile) {
            let currentUsersListOfFollowers = []
            if (this.currentUser.listOfFollowers) {
                currentUsersListOfFollowers = JSON.parse(this.currentUser.listOfFollowers)
            }

            currentUsersListOfFollowers.push(this.selectedEntity.username)

            let input = {
                pkName: 'pk',
                pkValue: this.currentUser.username,
                skName: 'sk',
                skValue: `BD#${this.currentUser.username}`,
                attributeName: 'listOfFollowers',
                attributeValue: JSON.stringify(currentUsersListOfFollowers),
            }
            await this.backendApiUsersService.updateSingleAttributeForUser(input)
            this.backendApiUsersService.initCurrentUser()
        }
    }

    signUp() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Sign Up',
                component: SignUpComponent,
                hasSubmitButton: true,
                hasCancelButton: false,
                hasLeftActionButton: true,
                leftActionButtonText: 'Start Over',
                allowParentClose: true,
            },
        })
        dialogRef.afterClosed().subscribe((data) => { })
    }

    openBulkRateGames() {
        let onboardingDialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Bulk Rate Games',
                // component: BulkRateGamesComponent,
                component: NewUserComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                hasLeftActionButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            width: '90%',
            height: '90%',
        })

        onboardingDialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => { })
    }



    viewProfileImage(selectedEntityImg) {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: null,
                component: FullSizedImageViewComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
                inputData: {
                    itemId: null,
                    image: selectedEntityImg,
                    numLikes: null,
                },
            },
            height: '70%',
            width: '90%',
        })
    }







    editProfile() {

        let editProfileRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Edit Profile',
                component: EditMyProfileComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                hasCloseButton: true,
                allowParentClose: true
            },
            panelClass: this.isMobileScreen ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS] : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true
        })

        editProfileRef.afterClosed().pipe(untilDestroyed(this)).subscribe(async data => {
            let updatedUserResult = await this.backendAPIUsersService.updateUser(this.currentUser, null)
            this.backendAPIUsersService.initCurrentUser();
        })
    }











}
