import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
// import { input } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs-compat';
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';
@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-compose-message',
  templateUrl: './compose-message.component.html',
  styleUrls: ['./compose-message.component.scss']
})
export class ComposeMessageComponent implements OnInit {

  toThisUserId
  toThisUserTitle
  selectedUser = null
  user

  previousMessageExists = false

  coreUsers = new BehaviorSubject(null)
  coreUsers$ = this.coreUsers.asObservable()

  inputData


  @Input() set _inputData(inputData) {


    if (!inputData) {
      return;
    }

    this.previousMessageExists = inputData.message !== undefined;

    this.inputData = inputData;

    let coreUsersToProcess = this.inputData.coreUsers || [];

    coreUsersToProcess = coreUsersToProcess.filter(user => user.coreSK !== '#undefined');

    this.coreUsers.next(coreUsersToProcess);
  }



  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {

    if (this.sendMessageMessage === undefined || this.sendMessageMessage.trim() === '') {
      alert('Message Required');
    } else {
      if (parentSubmitClicked) {
        this.sendMessageToASpecificUser();
      }
    }
  }

  // ! We want to take an input then display it here
  previousMessage = ''
  sendToUserId = ''

  constructor(
    private backendAPIUsersService: BackendAPIUsersService,
    private backendApiMessageService: BackendAPIMessageService,
    private dialogRef: MatDialogRef<ComposeMessageComponent>
  ) { }

  toUserObject


  ngOnInit(): void {

    this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user

      if (this.user.username != this.inputData.user.username) {
        this.selectedUser = [
          {
            title: this.inputData.user.title,
            username: this.inputData.user.username
          }
        ]

        this.toThisUserId = this.inputData.user.username
        this.toThisUserTitle = this.inputData.user.title

      }

    })

    // * REPLYING
    if (this.inputData.message) {
      this.toThisUserId = this.inputData.message.createdBy
      this.previousMessage = this.inputData.message.message
      this.sendToUserId = this.inputData.message.createdBy
      this.selectedUser = [{ title: this.inputData.message.sentFromTitle, id: this.inputData.message.createdBy }]
    }
  }




  selectUser(user) {
    this.toThisUserId = user[0].username
    this.toThisUserTitle = user[0].title
  }


  type
  sendMessageMessage

  sendMessageInput
  async sendMessageToASpecificUser() {

    if (this.inputData.message) {
      // todo NEED MORE PARAMETERS HERE

      this.sendMessageInput = {
        message: this.sendMessageMessage,
        createdByUID: this.user.username,
        sentFromTitle: this.user.title,
        sentToTitle: this.toThisUserTitle,
        sentToUID: this.inputData.message.createdBy
      }
    } else {
      // todo NEED MORE PARAMETERS HERE

      this.sendMessageInput = {
        message: this.sendMessageMessage,
        createdByUID: this.user.username,
        sentFromTitle: this.user.title,
        sentToTitle: this.toThisUserTitle,
        sentToUID: this.toThisUserId
      }

    }

    await this.backendApiMessageService.createMessageItem(this.sendMessageInput)
    this.sendMessageMessage = ''

    this.dialogRef.close()

  }

  async createRequestItem() {

    // todo NEED MORE PARAMETERS HERE
    let sendMessageInput = {
      type: this.type,

      createdByUID: this.user.username,
      sentFromTitle: this.user.title,

      sentToTitle: this.toThisUserTitle,
      sentToUID: this.toThisUserId
    }
    await this.backendApiMessageService.createRequestItem(sendMessageInput)
    this.sendMessageMessage = ''
  }
}


//   messageInputData
//   @Input() set _messageInputData(messageInputData) {
//     this.messageInputData = messageInputData
// }
