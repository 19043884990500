<div class="parent-container">
    <div *ngIf="!isMobileScreen">
        <mat-table id="desktop-table" [dataSource]="dataSource" matSort matSortDirection="asc">
            <ng-container matColumnDef="gamePlayed" class="header-item">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Game </mat-header-cell>
                <mat-cell
                    class="gh-link"
                    style="cursor: pointer"
                    (click)="openGameDetails(element)"
                    *matCellDef="let element"
                >
                    {{ element.gamePlayed }}
                </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef> Min </th>
                <td mat-cell *matCellDef="let element"> {{element.duration}} </td> 
            </ng-container> -->

            <ng-container matColumnDef="otherPlayers" class="header-item">
                <mat-header-cell *matHeaderCellDef> Other Players </mat-header-cell>

                <ng-container matColumnDef="otherPlayers">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Players </mat-header-cell>
                    <mat-cell
                        *matCellDef="let element"
                        matTooltip="{{
                            element.commaSeparatedPlayersList.length > tooltipMaxLength
                                ? element.commaSeparatedOthersList
                                : ''
                        }}"
                        matTooltipPosition="above"
                    >
                        {{ truncate(element.commaSeparatedPlayersList, tooltipMaxLength) }}
                    </mat-cell>
                </ng-container>
            </ng-container>

            <ng-container matColumnDef="date" class="header-item">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.date | date : 'MM/dd/yy' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="view" class="header-item">
                <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-icon
                        class="gh-icon gh-icon-clickable gh-icon-primary"
                        matTooltip="View Detail"
                        matTooltipPosition="above"
                        (click)="viewLPDetail(element)"
                    >
                        zoom_in
                    </mat-icon>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="edit" class="header-item">
                <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-icon
                        class="gh-edit-icon gh-icon gh-icon-clickable"
                        matTooltip="Edit"
                        matTooltipPosition="above"
                        (click)="editLP(element)"
                    >
                        edit
                    </mat-icon>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <!-- <tr (click)="logRow(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>

        <mat-paginator
            id="paginator"
            #paginator
            [pageSize]="5"
            [pageSizeOptions]="[5, 15, 100, 500]"
            showFirstLastButtons
        ></mat-paginator>
    </div>

    <div *ngIf="isMobileScreen" id="mobile-table-container">
        <mat-table [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="gamePlayed">
                <th mat-header-cell *matHeaderCellDef>Game</th>
                <td mat-cell *matCellDef="let row">{{ truncate(row.gamePlayed, 40) }}</td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let row">{{ row.date | date : 'MM/dd/yy' }}</td>
            </ng-container>

            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <mat-icon class="down-arrow">keyboard_arrow_down</mat-icon>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="mobileColumns.length">
                    <div
                        class="element-detail"
                        [@detailExpand]="element == mobilExpandedElement ? 'expanded' : 'collapsed'"
                    >
                        <div class="my-3 row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-card-title class="gh-xxsm-title"
                                            >Public Notes:</mat-card-title
                                        >
                                    </div>
                                    <div class="col-12">
                                        <mat-card-subtitle
                                            >{{ element.publicNotes | nullPlaceholder }}
                                        </mat-card-subtitle>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-card-title class="gh-xxsm-title"
                                            >Players:</mat-card-title
                                        >
                                    </div>
                                    <div class="col-12">
                                        <mat-card-subtitle>{{
                                            truncate(
                                                element.commaSeparatedPlayersList,
                                                tooltipMaxLength
                                            ) | nullPlaceholder
                                        }}</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-1 text-center col-12">
                                <div class="row">
                                    <div class="col-4">
                                        <mat-icon
                                            class="gh-icon gh-icon-clickable gh-icon-primary"
                                            matTooltip="View Detail"
                                            matTooltipPosition="above"
                                            (click)="viewLPDetail(element)"
                                        >
                                            zoom_in
                                        </mat-icon>
                                    </div>

                                    <div class="col-4">
                                        <mat-icon
                                            class="gh-edit-icon gh-icon gh-icon-clickable"
                                            matTooltip="Edit"
                                            matTooltipPosition="above"
                                            (click)="editLP(element)"
                                        >
                                            edit
                                        </mat-icon>
                                    </div>

                                    <div class="col-4">
                                        <button
                                            mat-button
                                            (click)="
                                                removeLPItem(element, this.currentUser.username)
                                            "
                                        >
                                            <mat-icon class="gh-delete-icon">delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="mobileColumns"></tr>
            <tr
                mat-row
                *matRowDef="let element; columns: mobileColumns"
                [class.expanded-row]="mobilExpandedElement === element"
                (click)="mobilExpandedElement = mobilExpandedElement === element ? null : element"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </mat-table>

        <mat-paginator
            id="paginator"
            #paginator
            [pageSize]="5"
            [pageSizeOptions]="[5, 15, 100, 500]"
            showFirstLastButtons
        ></mat-paginator>
    </div>
</div>
