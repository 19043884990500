import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants';
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component';
import { MessageDetailsComponent } from '../message-details/message-details.component';
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service';
import { ArrayHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/array-helper.service';
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service';
import { MessageTableType } from 'src/app/dashboard/dashboard-shared/enums/message-type-enums';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service';

@Component({
  selector: 'app-messages-table',
  templateUrl: './messages-table.component.html',
  styleUrls: ['./messages-table.component.scss']
})
export class MessagesTableComponent implements OnInit {

  defaultUserImage = window.location.origin + '/assets/images/defaults/profile-placeholder.png'

  selectedIndex = null

  @ViewChild(MatTable) table: MatTable<any>

  dataSource = new MatTableDataSource<any>()
  @Input() set _dataSource(dataSource) {
    this.dataSource = new MatTableDataSource<any>(dataSource)
    this.getUserImages()
  }

  messageTableType
  messageTableTypeString
  @Input() set _messageTableType(messageTableType: MessageTableType) {
    this.messageTableType = messageTableType
    this.messageTableTypeString = MessageTableType[messageTableType]
  }

  displayedColumns
  @Input() set _displayedColumns(displayedColumns: string[]) {
    this.displayedColumns = displayedColumns
  }

  user
  @Input() set _user(user: boolean) {
    this.user = user
  }

  hasDeleteButtons
  @Input() set _hasDeleteButtons(hasDeleteButtons: boolean) {
    this.hasDeleteButtons = hasDeleteButtons
  }

  hasArchiveButtons
  @Input() set _hasArchiveButtons(hasArchiveButtons: boolean) {
    this.hasArchiveButtons = hasArchiveButtons
  }

  @Output() composeMessageEmitter = new EventEmitter()

  constructor(
    private dialog: MatDialog,
    private screenSizeService: ScreenSizeService,
    private arrayHelperService: ArrayHelperService,
    private snackbarService: SnackbarService,

    private backendApiMessageService: BackendAPIMessageService,
    private backendApiUsersService: BackendAPIUsersService
  ) { }

  isMobileScreen = false
  ngOnInit(): void {
    this.screenSizeService.isMobileScreen$
      .pipe(untilDestroyed(this))
      .subscribe((isMobileScreen: boolean) => {
        this.isMobileScreen = isMobileScreen
      })
  }


  async getUserImages() {
    if (this.dataSource.filteredData && this.dataSource.filteredData.length > 0) {
      for (let item of this.dataSource.filteredData) {
        let res
        switch (this.messageTableType) {
          case MessageTableType.inbox:
            res = await this.backendApiUsersService.GetUserMainImageFiles(item.createdBy)
            item.mainImageFiles = res.mainImageFiles
          case MessageTableType.sent:
            res = await this.backendApiUsersService.GetUserMainImageFiles(item.sentToUID)
            item.mainImageFiles = res.mainImageFiles

          //? do we actually want o show an image for archived? if so which one?
          // case MessageTableType.archived:
          //   if (item.sentToUID) {
          //     res = await this.backendApiUsersService.GetUserMainImageFiles(item.sentToUID)
          //     item.mainImageFiles = res.mainImageFiles
          //   }
          //   else {
          //     res = await this.backendApiUsersService.GetUserMainImageFiles(item.createdBy)
          //     item.mainImageFiles = res.mainImageFiles
          //   }
        }
      }
    }
  }



  seeMessageDetails(item) {
    let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
      panelClass: this.isMobileScreen
        ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
        : DESKTOP_MODAL_PANEL_CLASS,
      backdropClass: 'gh-dialog-backdrop',
      data: {
        title: 'Message Details',
        component: MessageDetailsComponent,


        inputData: item,


        hasSubmitButton: true,
        hasCancelButton: true,
        submitButtonText: 'Reply',
        allowParentClose: true,
        hasLeftActionButton: true,
        leftActionButtonText: 'Delete Message',
      },
    })

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.composeMessageEmitter.emit({ currentUser: data.currentUser, messageToReplyTo: data.messageToReplyTo })
        }
      })
  }

  async deleteMessageItem(userID, item) {

    let messageInput = { pk: userID, sk: item.sk_type_ULID }

    let deleteResult = await this.backendApiMessageService.deleteMessage(messageInput)

    // todo Need to delete the message on teh recipieants data

    this.snackbarService.openSuccessSnackBar('Processing, one moment...')

    if (deleteResult) {
      this.dataSource = this.arrayHelperService.removeFromArray(
        this.dataSource,
        item,
        true,
        'sk_type_ULID',
      )
      this.table.renderRows()
    }
    // todo may Need a little logic here to see if we are in data (received) or data2 (sent messages)
    // todo Something unfinished here? Stuff like this is avail on delete result #Archived#01hpm1j0hb9aycckye2q3jd5f2

  }

  async archiveMessageItem(userID, item) {
    let messageInput = { pk: userID, sk: item.sk_type_ULID }
    let deleteResult = await this.backendApiMessageService.deleteMessage(messageInput)

    let archiveMessageInput = {
      message: item.message,
      createdByUID: this.messageTableType == MessageTableType.inbox ? item.pk_UID : item.createdBy, //! this is what dictates its storage
      sentToUID: item.pk_UID,
      sentFromTitle: item.sentFromTitle,
      sentToTitle: item.sentToTitle,
    }

    this.snackbarService.openSuccessSnackBar('Processing, one moment...')

    let archiveResult = await this.backendApiMessageService.createArchivedMessageItem(archiveMessageInput)

    if (archiveResult) {
      this.dataSource = this.arrayHelperService.removeFromArray(
        this.dataSource,
        item,
        true,
        'sk_type_ULID',
      )
      this.table.renderRows()
    }
  }

  onPanelOpened(index) {
    this.selectedIndex = index
  }

  onPanelClosed(index) {
    if (index == this.selectedIndex) {
      this.selectedIndex = null
    }
  }

}
