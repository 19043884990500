import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectorRef,
    Input,
    HostListener,
} from '@angular/core'
import { CsProfileGamesGamesILoveComponent } from './cs-components/games/cs-profile-games-games-i-love/cs-profile-games-games-i-love.component'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
    DISCOVER_GAMES_SUGGESTED_NOT_LOGGED_IN,
    DISCOVER_GAMES_TRENDING_NOT_LOGGED_IN,
} from 'src/app/dashboard/constants/mock-data'
import { RADAR_CHART_PROFILE_FRIEND_GAME_LABELS } from 'src/app/dashboard/constants/radar-chart-constants'
import {
    RadarChartTypeEnums,
    RadarChartOverlayTypeEnums,
} from 'src/app/dashboard/enums/radar-chart-enums'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { BannerLocationEnums } from '../../../enums/banner-location-enums'
import { BannerSizeEnums } from '../../../enums/banner-size-enums'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { BannerAdService } from '../../../services/ads/banner-ad.service'
import { ProfileService } from '../../../services/profile/profile.service'
import { GameDetailPreviewModalComponent } from '../../games/game-detail-preview-modal/game-detail-preview-modal.component'
import { CsProfileGamesLookingToPlayComponent } from './cs-components/games/cs-profile-games-looking-to-play/cs-profile-games-looking-to-play.component'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { BehaviorSubject } from 'rxjs'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { MdGameDetailComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/md-components/md-game-detail/md-game-detail.component'
import { EditProfileGameCollectionsComponent } from './components/edit-profile-game-collections/edit-profile-game-collections.component'
import { UserGameCollectionTypeEnums } from '../../../../../../../../common/enums/CommonEnums'
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { MdService } from '../../../services/master-detail/master-detail.service'
import { BackendApiHavenTrackingService } from 'src/app/backend-api-services/backend-api-haven-tracking.service'
import { GamenightComponent } from '../../events/gamenight/gamenight.component'
import { ComposeMessageComponent } from 'src/app/dashboard/components/sidebar/components/messaging/components/compose-message/compose-message.component'
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service'
import { TimingHelperService } from '../../../services/helpers/timing-helper.service'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { UsersService } from '../../../services/users/users.service'
import { HopperComponent } from '../../hopper/hopper/hopper.component'
import { HopperCompetitionLevelEnums } from '../../../enums/hopper-enums'
import { GamesService } from '../../../services/games/games.service'
import { MatDialog } from '@angular/material/dialog'
import { GhButtonColor } from '../../../enums/gh-button-enums'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
    @ViewChild('miniRadarChart', { static: true }) miniRadarChart

    bannerAdInputDataSpecific = {
        bannerSizeEnum: BannerSizeEnums.banner120x240,
        multiplier: 0.75,
        bannerLocation: BannerLocationEnums.sidebar,
        hasButtons: true,
        specificAds: [
            {
                link: '//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=davidrazidlo-20&marketplace=amazon&region=US&placement=B084Z8L6KZ&asins=B084Z8L6KZ&linkId=73f096a89d123bfe420da6b63d2bada2&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066C0&bg_color=FFFFFF',
            },
        ],
        isIFrame: true,
    }

    defaultMainImage = window.location.origin + '/assets/images/defaults/placeholder-event.png'

    gamesILoveLoggedIn = 'Games I Love:'
    gamesILovedNotLoggedIn = 'Games I (might) Love'
    gamesIWantToPlayLoggedIn = 'Looking to Play:'
    gamesIWantToPlayNotLoggedIn = 'I (might) want to play:'

    userIsAdmin = false

    gamesILove = []
    lookingToPlay = []
    myCollection = []

    isCurrentUsersProfile
    isLoading = new BehaviorSubject(true)
    editButtonShows = true
    radarChartType = RadarChartTypeEnums.profile
    gamesContentComponents = [
        CsProfileGamesLookingToPlayComponent,
        CsProfileGamesGamesILoveComponent,
    ]
    isMobileScreen = false
    carouselContent = []
    myUserGameQualities

    loggedInUser

    selectedEntity = new BehaviorSubject(null)
    selectedEntity$ = this.selectedEntity.asObservable()

    selectedUserIsFriend

    radarData //! radarData is initiated here
    radarOverlayType = RadarChartOverlayTypeEnums.myAttributes

    isAuthenticated = new BehaviorSubject(false)

    myFriends = new BehaviorSubject(null)
    myFriends$ = this.myFriends.asObservable()

    isViewingFriend = false
    inputData
    @Input() set _inputData(inputData) {
        this.inputData = inputData
    }

    @Output() onEditProfile = new EventEmitter()

    isTabFocused = false
    isScrolling = false

    constructor(
        private backendAPIUsersService: BackendAPIUsersService,
        private profileService: ProfileService,
        private screenSizeService: ScreenSizeService,
        private bannerAdService: BannerAdService,
        private dialog: MatDialog,
        private authService: AuthService,
        private backendApiGamesService: BackendAPIGamesService,
        private backendApiGameTrackingService: BackendApiGameTrackingService,
        private backendApiHavenTrackingService: BackendApiHavenTrackingService,
        private backendApiContentService: BackendAPIContentService,
        private backendApiMessageService: BackendAPIMessageService,
        private changeDetectorRef: ChangeDetectorRef,
        private mdService: MdService,
        private usersService: UsersService,
        private timingHelperService: TimingHelperService,
    ) {
        changeDetectorRef.detach()
        setInterval(() => {
            this.changeDetectorRef.detectChanges()
        }, 500)

        this.isScrolling = false

        this.isTabFocused = true

        document.addEventListener('visibilitychange', () => {
            this.isTabFocused = !document.hidden
        })

        window.addEventListener('scroll', () => {
            this.isScrolling = true
            setTimeout(() => {
                this.isScrolling = false
            }, 1000) // reset scrolling state after 1 second
        })
    }

    currentUser
    userIsActive = false
    async ngOnInit() {
        this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((currentUser) => {
                if (currentUser) {
                    this.currentUser = currentUser
                    this.selectedEntity.next(currentUser) // needs to be here rather than only in following methods to refresh data
                }
            })

        this.usersService.myFriends$.pipe(untilDestroyed(this)).subscribe((myFriends) => {
            this.myFriends.next(myFriends)
        })

        // opened as the non logged-in user
        if (this.inputData) {
            let userToPassIn = this.inputData.user ? this.inputData.user : this.inputData.friend

            this.initLoggedInUser(userToPassIn)
            this.isAuthenticated.next(true)
        } else {
            // opened normally as the logged-in user

            this.authService.authStatusChanged
                .pipe(untilDestroyed(this))
                .subscribe(async (authenticated) => {
                    this.isAuthenticated.next(authenticated)
                    if (authenticated) {
                        // user is signed incx
                        await this.backendAPIUsersService.currentUser$
                            .pipe(untilDestroyed(this))
                            .subscribe(async (currentUser) => {
                                if (currentUser) {
                                    this.initLoggedInUser(currentUser)
                                }
                            })
                    } else {
                        // user is not signed in
                        this.setValuesForNonSignedInUser()
                    }
                })
            this.authService.checkAuth() //* this line needs to be AFTER the above subscription
        }

        function getRandomInt(max) {
            return Math.floor(Math.random() * max)
        }

        this.bannerAdInputDataSpecific.specificAds = [
            { link: this.bannerAdService.amazonBannerAds[getRandomInt(4)].l },
        ]

        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })
    }

    setValuesForNonSignedInUser() {
        this.gamesILove = DISCOVER_GAMES_SUGGESTED_NOT_LOGGED_IN // todo this working?
        this.lookingToPlay = DISCOVER_GAMES_TRENDING_NOT_LOGGED_IN // todo this working?

        this.selectedEntity.next({
            profileImages: [''],
        })
    }

    friendRequestData = new BehaviorSubject({
        friendReqeustOutToSelectedUser: null,
        selectedUserIsReqeustingIFriendThem: null,
    })
    friendRequestData$ = this.friendRequestData.asObservable()

    async initLoggedInUser(currentUser) {
        this.loggedInUser = currentUser //* NOTE: Need loggenInUser even if viewing friends profile for radar overlay and stuff!!!
        this.selectedEntity.next(currentUser) // not sure if this is ideal, but makes things show up on friends modal
        this.isCurrentUsersProfile = this.profileService.getIsCurrentUsersProfile()

        if (this.isCurrentUsersProfile && !this.inputData) {
            // looking at MY profile
            await this.initAuthenticatedSelectedEntity(currentUser)
        } else if (this.isCurrentUsersProfile && this.inputData) {
            await this.initAuthenticatedSelectedEntity(this.inputData.user)
        } else if (!this.isCurrentUsersProfile && this.inputData) {
            await this.initAuthenticatedSelectedEntity(currentUser)
        } else {
            // looking at a FRIENDS profile
            this.isViewingFriend = true

            this.mdService.selectedItem$
                .pipe(untilDestroyed(this))
                .subscribe(async (selectedFriend: any) => {
                    //! ***********************************************
                    //! The issue is here, we might be looking at a friend, then want to see the details
                    //! the selected friend is called here, not the friend from the 'Full Details'

                    //! ***********************************************

                    if (selectedFriend) {
                        let selectedUserFriendRequests =
                            await this.backendApiMessageService.listMyFriendRequests(
                                selectedFriend.username,
                                20,
                            )

                        let indRequests = selectedUserFriendRequests.requests

                        if (indRequests && indRequests.length > 0) {
                            // todo Extract relevant item from the list of requests and Send data to the button?

                            this.friendRequestData.next({
                                selectedUserIsReqeustingIFriendThem: indRequests.some(
                                    (r) => r.sentToUID == this.currentUser.username,
                                ),
                                friendReqeustOutToSelectedUser: indRequests.some(
                                    (r) => r.createdBy == this.currentUser.username,
                                ),
                            })
                        } else {
                            this.friendRequestData.next({
                                selectedUserIsReqeustingIFriendThem: false,
                                friendReqeustOutToSelectedUser: false,
                            })
                        }

                        let userToSearchWith = selectedFriend.username

                        // todo need to modify the array here

                        let arrayToSearch = this.myFriends.value
                        let simpleArrayOfSK = arrayToSearch.map((object) => object.sk)
                        let simpleErArrayOfSK = simpleArrayOfSK.map((str) =>
                            str.replace('#friendOf#', ''),
                        )

                        if (simpleErArrayOfSK) {
                            let isFriend = simpleErArrayOfSK.includes(userToSearchWith)
                            this.selectedUserIsFriend = isFriend
                        }

                        console.log('777 selectedFriend', selectedFriend)

                        await this.initAuthenticatedSelectedEntity(selectedFriend)
                    }
                })
        }
        this.isLoading.next(false)
    }

    viewFullRadarChart() {
        if (this.miniRadarChart) {
            // TODO: eventually should hide the view detail button until it component is loaded rather than this check
            this.miniRadarChart.openDialog()
        }
    }

    async logEmittedResults(event) {
        if (event == 'resetData') {
            this.timingHelperService.delay(2000).then(async () => {
                await this.initAuthenticatedSelectedEntity(this.currentUser)
            })

            // this.radarData = {
            //   labels: RADAR_CHART_PROFILE_FRIEND_GAME_LABELS,
            //   //! return this testing below attributes: this.selectedGame.big8,
            //   attributes: JSON.parse(this.selectedGame.big8), // todo simulating fetch of game Big8
            //   // overlayAttributes: this.user.radarAttributes // TODO: FETCH USER DATA
            //   // overlayAttributes: [8, 6, 5, 3, 4, 7, 5, 9] // TODO: FETCH USER DATA
            //   overlayAttributes: this.myUserGameQualities.userGameQualities // TODO: FETCH USER DATA
            // }
        }
    }

    myHavensResult
    async initMyHavens(userId) {
        // ! swap to other service
        let myHavensResult = await this.backendApiHavenTrackingService.listMyHavens(
            userId,
            49,
            null,
        )

        if (myHavensResult) {
            this.myHavensResult = myHavensResult.havens

            for (let haven of this.myHavensResult) {
                let mainImageFilesResult = await this.backendApiGamesService.GetMainImage(haven.id)

                if (mainImageFilesResult) {
                    haven.mainImageFiles = mainImageFilesResult.mainImageFiles
                }
            }
        }
    }

    async initGamesILove(userId) {
        let gilResult = await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(
            userId,
            '#GIL#',
            50,
            null,
        )

        let tempGIL = gilResult.games // needed because gilResult has mainImageFiles that are your avatar

        for (let game of tempGIL) {
            let mainImageFilesResult = await this.backendApiGamesService.GetMainImage(game.id)
            game.mainImageFiles = mainImageFilesResult.mainImageFiles
        }

        this.gamesILove = tempGIL
    }

    async initLookingToPlay(userId) {
        let ltpResult = await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(
            userId,
            '#LTP#',
            50,
            null,
        )

        let tempLTP = ltpResult.games // needed because ltpResult has mainImageFiles that are your avatar

        for (let game of tempLTP) {
            let mainImageFilesResult = await this.backendApiGamesService.GetMainImage(game.id)
            game.mainImageFiles = mainImageFilesResult.mainImageFiles
        }

        this.lookingToPlay = tempLTP
    }

    async initFetchLoggedPlays(userId) {
        let lpOfThisUser = await this.backendApiContentService.listLPByUserId(userId, 50)
    }

    async initAuthenticatedSelectedEntity(entity) {
        // currentUser or Friend

        this.selectedEntity.next(entity)

        let fullDetails = await this.backendAPIUsersService.getProfileById(
            this.selectedEntity.value.username,
        )

        console.log('777 selected User in Profile GOOD', fullDetails) //! good to here

        this.selectedEntity.value.fullDetails = fullDetails

        // Issue is very near hear? How and why do we set 'selectedEntityBio'

        this.myUserGameQualities = await this.backendAPIUsersService.getUserGameQualitiesById(
            this.selectedEntity.value.username,
        )

        let bob = JSON.parse(this.myUserGameQualities.userGameQualities)

        this.initGamesILove(this.selectedEntity.value.username)
        this.initLookingToPlay(this.selectedEntity.value.username)
        this.initFetchLoggedPlays(this.selectedEntity.value.username)
        this.initMyHavens(this.selectedEntity.value.username)

        if (this.isCurrentUsersProfile) {
            this.radarData = {
                labels: RADAR_CHART_PROFILE_FRIEND_GAME_LABELS,
                attributes: [bob[0], bob[1], bob[2], bob[3], bob[4], bob[5], bob[6], bob[7]],
            }
        } else {
            let overlayGameQualities = await this.backendAPIUsersService.getUserGameQualitiesById(
                this.currentUser.username,
            )

            let carl = JSON.parse(overlayGameQualities.userGameQualities)

            this.radarData = {
                labels: RADAR_CHART_PROFILE_FRIEND_GAME_LABELS,
                attributes: [bob[0], bob[1], bob[2], bob[3], bob[4], bob[5], bob[6], bob[7]],
                overlayAttributes: [
                    carl[0],
                    carl[1],
                    carl[2],
                    carl[3],
                    carl[4],
                    carl[5],
                    carl[6],
                    carl[7],
                ],
            }
        }

        this.isLoading.next(false)
    }

    openDetailModal(item) {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: item.title,
                component: GameDetailPreviewModalComponent,
                inputData: item,
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Full Detail',
                allowParentClose: true,
            },
        })
        dialogRef.afterClosed().subscribe((data) => {})
    }

    getBadgeTooltip(obtained) {
        return obtained
            ? 'You earned this by scoring 20,000 exp'
            : 'To earn this badge, you earn 1403 more exp'
    }

    getCellsToShow() {
        if (this.carouselContent.length < 3) {
            return this.carouselContent.length
        } else {
            return 3
        }
    }

    //! THIS IS WRONG?

    contactButtonClicked() {
        let userObject = {
            title: this.selectedEntity.value.title,
            username: this.selectedEntity.value.username,
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Compose Message',
                component: ComposeMessageComponent,

                inputData: { user: userObject, message: undefined, coreUsers: [userObject] },

                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Send Message',
                allowParentClose: true,
                hasCloseButton: true,
            },
            autoFocus: false, //! prevents modal from auto opening the user selection dropdown!!!
        })
    }

    editGamesILove() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Choose Games I Love',
                component: EditProfileGameCollectionsComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
                hasCloseButton: true,
                inputData: {
                    isMultiSelect: true,
                    userGameCollectionType: UserGameCollectionTypeEnums.gamesILove,
                    collection: this.gamesILove,
                },
            },
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                if (data) {
                    let gamesToAddToGameTrackingTable = []
                    for (let game of data) {
                        if (!this.gamesILove.some((g) => g.id === game.pk)) {
                            gamesToAddToGameTrackingTable.push(game)
                        }
                    }

                    if (gamesToAddToGameTrackingTable.length > 0) {
                        await this.backendApiGameTrackingService.createMultipleGameTrackingAddToGamesILove(
                            gamesToAddToGameTrackingTable,
                            this.loggedInUser.username,
                            this.loggedInUser.title,
                            this.loggedInUser.mainImageFiles && this.loggedInUser.mainImageFiles[0]
                                ? this.loggedInUser.mainImageFiles[0]
                                : null,
                            this.loggedInUser.state,
                            this.loggedInUser.zipCode,
                        )
                    }

                    let gamesToRemoveFromGameTrackingTable = []
                    for (let game of this.gamesILove) {
                        if (!data.some((g) => g.pk === game.id)) {
                            gamesToRemoveFromGameTrackingTable.push(game)
                        }
                    }
                    if (gamesToRemoveFromGameTrackingTable.length > 0) {
                        await this.backendApiGameTrackingService.deleteMultipleGameTrackingGamesILove(
                            gamesToRemoveFromGameTrackingTable,
                            this.loggedInUser.username,
                        )
                    }
                }

                this.timingHelperService.delay(1000).then(() => {
                    this.initGamesILove(this.selectedEntity.value.username)
                })
            })
    }

    editLookingToPlay() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Choose Games you would Love to Play',
                component: EditProfileGameCollectionsComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
                hasCloseButton: true,
                inputData: {
                    isMultiSelect: true,
                    userGameCollectionType: UserGameCollectionTypeEnums.lookingToPlay,
                    collection: this.lookingToPlay,
                },
            },
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                if (data) {
                    let gamesToAddToGameTrackingTable = []
                    for (let game of data) {
                        if (!this.lookingToPlay.some((g) => g.id === game.pk)) {
                            gamesToAddToGameTrackingTable.push(game)
                        }
                    }

                    if (gamesToAddToGameTrackingTable.length > 0) {
                        let gamesToAddToGameTrackingTableResult =
                            await this.backendApiGameTrackingService.createMultipleGameTrackingAddToLookingToPlay(
                                gamesToAddToGameTrackingTable,
                                this.loggedInUser.username,
                                this.loggedInUser.state,
                                this.loggedInUser.zipCode,
                                this.loggedInUser.title,
                                this.loggedInUser.mainImageFiles &&
                                    this.loggedInUser.mainImageFiles[0]
                                    ? this.loggedInUser.mainImageFiles[0]
                                    : null,
                            )
                    }

                    let gamesToRemoveFromGameTrackingTable = []
                    for (let game of this.lookingToPlay) {
                        if (!data.some((g) => g.pk === game.id)) {
                            gamesToRemoveFromGameTrackingTable.push(game)
                        }
                    }
                    if (gamesToRemoveFromGameTrackingTable.length > 0) {
                        let gamesToRemoveFromGameTrackingTableResult =
                            await this.backendApiGameTrackingService.deleteMultipleGameTrackingLookingToPlay(
                                gamesToRemoveFromGameTrackingTable,
                                this.loggedInUser.username,
                            )
                    }
                }

                this.timingHelperService.delay(1000).then(() => {
                    this.initLookingToPlay(this.selectedEntity.value.username)
                })
            })
    }

    async onGameDetailSelected(event) {
        let gameDetails = await this.backendApiGamesService.GetGame(event.id)

        let inputData = {
            limitDataFecthing: true,
            game: gameDetails,
            origin: 'modal',
        }

        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: gameDetails.title,
                component: MdGameDetailComponent,
                hasSubmitButton: false,
                hasCloseButton: true,
                hasCancelButton: false,
                inputData: inputData, //! what does limit data fetching do???
                allowParentClose: true,
            },
            maxWidth: '90%',

            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,

            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
    }
}
