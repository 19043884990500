import { Component, HostListener, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { LogInComponent } from '../../../log-in/log-in.component'
import { SignUpComponent } from '../../../sign-up/sign-up.component'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { MatDialog } from '@angular/material/dialog'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-public-header',
    templateUrl: './public-header.component.html',
    styleUrls: ['./public-header.component.scss'],
})
export class PublicHeaderComponent implements OnInit {
    @ViewChild('header', { static: false }) header: any
    @ViewChild('logo', { static: false }) logo: any

    isMobileScreen = true

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private screenSizeService: ScreenSizeService,
    ) {}

    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })
    }

    goHome() {
        console.log('wtf omg')
        //! Important: router.navigate must come after sidebarService.updateSelectedItem, otherwise the route param doesn't work
        // this.sidebarService.updateSelectedItem('PROFILE')
        window.location.assign('https://mygamehaven.com/#/public/home')
    }

    // ! this was causing problems with the logo and the alpha badge
    // @HostListener('window:scroll', ['$event'])
    // onScroll(event) {
    //     if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
    //         this.header.nativeElement.style.height = "60px"
    //         this.logo.nativeElement.style.height = "45px"
    //     } else {
    //         this.header.nativeElement.style.height = "100px"
    //         this.logo.nativeElement.style.height = "80px"
    //     }
    // }

    signUp() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: false,
            data: {
                title: 'Sign Up',
                component: SignUpComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                hasLeftActionButton: false,
                // leftActionButtonText: 'Start Over',
                allowParentClose: true,
                hasCloseButton: false,
            },
            maxWidth: '95vw',
            maxHeight: '95vh',
        })

        dialogRef.afterClosed().subscribe((data) => {})
    }
    currentVersion() {
        this.router.navigate(['public/home'])
    }

    version2() {
        this.router.navigate(['public/home2'])
    }

    version3() {
        this.router.navigate(['public/home3'])
    }

    logIn() {
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: false,
            data: {
                title: 'Log In',
                component: LogInComponent,
                hasSubmitButton: true,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
            },
        })
    }

    goToMyGh() {
        this.router.navigate(['./../../dashboard'], { relativeTo: this.route })
    }
}
