import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { ADMIN_USER_LEVEL_THRESHOLD, DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants';
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service';
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service';
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';
import { DELETE_CONFIRMATION_PASSWORD, MdGameDetailComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/md-components/md-game-detail/md-game-detail.component';
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service';
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component';
import { iProfileAddOrEditSimpleEventInput } from '../../../interfaces/events/iProfileAddOrEditSimpleEventInput';
import { ArrayHelperService } from '../../../services/helpers/array-helper.service';
import { UsersService } from '../../../services/users/users.service';
import { ImageGalleryGridComponent } from '../../media/image-gallery-grid/image-gallery-grid.component';
import { ConfirmActionComponent } from '../../modals/confirm-action/confirm-action.component';
import { ProfileAboutMeFriendsFriendDetailModalComponent } from '../../profile/profile-about-me/components/profile-about-me-friends/components/profile-about-me-friends-friend-detail-modal/profile-about-me-friends-friend-detail-modal.component';
import { ProfileAddOrEditSimpleEventComponent } from '../../profile/profile-calendar/components/profile-add-or-edit-simple-event/profile-add-or-edit-simple-event.component';
import { ProfileViewEventDetailsComponent } from '../../profile/profile-calendar/components/profile-view-event-details/profile-view-event-details.component';
import { SnackbarService } from '../../../services/user-action-feedback/snackbar.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-find-events-view-event-details',
  templateUrl: './find-events-view-event-details.component.html',
  styleUrls: ['./find-events-view-event-details.component.scss']
})
export class FindEventsViewEventDetailsComponent implements OnInit {

  @ViewChild('friendInput', { static: false }) friendInput


  coreUsers = new BehaviorSubject(null)
  coreUsers$ = this.coreUsers.asObservable()

  coreUserForFilter

  loggedPlays

  thumbs = new BehaviorSubject(null)
  thumbs$ = this.thumbs.asObservable()

  videos = new BehaviorSubject(null)
  videos$ = this.videos.asObservable()

  inputData
  attendees = []
  @Input() set _inputData(inputData) {

    this.inputData = inputData

    this.initAttendees()
    this.initLP()
    this.fetchImagesForEvent()
    this.initEventVideos()
    this.initInvitedUsers()

    // this.coreUsers.next(this.inputData.coreUsers)
  }

  parentDeleteButtonClicked = false
  @Input() set _parentDeleteButtonClicked(parentDeleteButtonClicked: boolean) {
    if (parentDeleteButtonClicked) {
      this.deleteEvent()
    }
  }

  parentLeftActionButtonClicked = false
  @Input() set _parentLeftActionButtonClicked(parentLeftActionButtonClicked: boolean) {
    if (parentLeftActionButtonClicked) {
      this.editEvent()
    }
  }


  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {

    if (parentSubmitClicked) {
      if (this.inputData.isInMyEvent == true) { // todo wrong?

        this.removeFromMySchedule()
      } else {
        this.addToMySchedule()
      }
    }
  }

  @Output() closeEmitter = new EventEmitter()

  async removeFromMySchedule() {

    let input = {
      pk: this.inputData.eventDetails.ulid,
      sk: 'e-new-' + this.user.username,
    }

    let deleteResult = await this.backendAPIContentService.deleteContentItemByPkSk(input)

    this.dialogRef.close('remove')
  }



  async addToMySchedule() {

    let newEvent = {

      date: this.eventDetails.value.date,
      end: this.eventDetails.value.end,
      havenId: this.user.username,
      havenZipCode: this.eventDetails.value.havenZipCode,
      pk: this.eventDetails.value.pk,
      prefix: `e-new-`.concat(this.user.username),
      title: this.eventDetails.value.eventTitle,

      extendedProps: {
        date: this.eventDetails.value.date,
        description: this.eventDetails.value.description,
        end: this.eventDetails.value.end,
        endTime: this.eventDetails.value.endTime,
        eventOrganizer: this.eventDetails.value.eventOrganizer,
        eventOrganizerId: this.eventDetails.value.eventOrganizerId,
        eventTitle: this.eventDetails.value.eventTitle,
        havenTitle: this.eventDetails.value.havenTitle,
        havenZipCode: this.eventDetails.value.havenZipCode,
        mainImageFiles: this.eventDetails.value.mainImageFiles,
        selectedGames: this.eventDetails.value.selectedGames,
        startTime: this.eventDetails.value.startTime,
        ulid: this.eventDetails.value.ulid,
        havenTitleText: this.eventDetails.value.havenTitleText,
        groupTitleText: this.eventDetails.value.groupTitleText
      },
    }

    this.backendAPIContentService.createCalendarItems(
      newEvent,
      this.inputData.eventDetails.mainImageFiles,
    )

    this.closeEmitter.emit('add')

  }



  async initLP() {
    let res = await this.backendAPIContentService.listLPByEventId(this.inputData.eventDetails.ulid, 50)

    let eventsWithDuplicates = res.loggedPlays

    let eventsWithoutDuplicates = eventsWithDuplicates.filter((event, index, self) => {
      return self.findIndex((otherEvent) => otherEvent.uniqueLpGroupingId === event.uniqueLpGroupingId) === index
    })

    this.loggedPlays = eventsWithoutDuplicates
  }




  listOfInvitedUsers = []
  uniqueInvitedUsers
  async initInvitedUsers() {



    try {
      const res = await this.backendAPIMessageService.listInvitesByEventID(this.inputData.eventDetails.ulid, 50);

      // Filter out unwanted invites
      this.listOfInvitedUsers = this.filterOutEventInvites(res.messages);

      // Fetch and attach main image files for each user
      for (let user of this.listOfInvitedUsers) {
        const mainImageFilesRes = await this.backendApiUsersService.GetUserMainImageFiles(user.sentToUID);
        user.mainImageFiles = mainImageFilesRes.mainImageFiles;
      }

    } catch (error) {
      // Handle any potential errors
      console.error('Error initializing invited users:', error);
    }

    this.uniqueInvitedUsers = this.listOfInvitedUsers.filter((user, index, self) =>
      self.findIndex(u => u.sentToTitle === user.sentToTitle) === index
    );


  }

  filterOutEventInvites(arr) {
    return arr.filter(obj => !obj.sk_type_ULID.startsWith('#EventInviteIncoming#'));
  }

  // Example Usage:
  // const objects = [
  //     { sk_type_ULID: '#EventInviteIncoming#12345' },
  //     { sk_type_ULID: 'OtherValue12345' },
  //     { sk_type_ULID: '#EventInviteIncoming#67890' },
  //     { sk_type_ULID: 'AnotherValue12345' }
  // ];

  // const filteredObjects = filterOutEventInvites(objects);

  async initAttendees() {
    if (this.inputData.attendees && this.inputData.attendees.calendarItems && this.inputData.attendees.calendarItems.length > 0) {
      for (let attendee of this.inputData.attendees.calendarItems) {
        if (attendee.sk == 'e') {
          // let result = await this.backendApiUsersService.getProfileById(attendee.extendedProps.eventOrganizerId)
          // this.attendees.push(result)
        } else {
          let attendeeId = attendee.sk.replace('e-new-', '')
          let result = await this.backendApiUsersService.getProfileById(attendeeId)
          let imagesRes = await this.backendApiUsersService.GetUserMainImageFiles(attendeeId)
          result.mainImageFiles = imagesRes.mainImageFiles
          this.attendees.push(result)
        }
      }
    }
  }


  constructor(
    private backendApiGamesService: BackendAPIGamesService,
    private dialog: MatDialog,
    private backendApiUsersService: BackendAPIUsersService,
    private backendAPIContentService: BackendAPIContentService,
    private snackbar: MatSnackBar,
    private arrayHelperService: ArrayHelperService,
    private backendAPIMessageService: BackendAPIMessageService,
    private dialogRef: MatDialogRef<ProfileViewEventDetailsComponent>,
    private screenSizeService: ScreenSizeService,
    private snackbarService: SnackbarService,

    private backendApiContentService: BackendAPIContentService,

    private usersService: UsersService,
  ) { }

  eventDetails = new BehaviorSubject(null)
  eventDetails$ = this.eventDetails.asObservable()

  myFriends = new BehaviorSubject(null)
  myFriends$ = this.myFriends.asObservable()

  user
  userIsAdmin = false
  isMobileScreen
  ngOnInit(): void {
    this.screenSizeService.isMobileScreen$
      .pipe(untilDestroyed(this))
      .subscribe((isMobileScreen: boolean) => {
        this.isMobileScreen = isMobileScreen
      })

    this.eventDetails.next(this.inputData.eventDetails)

    this.usersService.myFriends$
      .pipe(untilDestroyed(this))
      .subscribe((myFriends) => {
        this.myFriends.next(myFriends)
      })


    this.backendApiUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user
      this.userIsAdmin = this.user.userLevel >= ADMIN_USER_LEVEL_THRESHOLD
    })

    this.backendApiUsersService.currentUser$
      .pipe(untilDestroyed(this))
      .subscribe(async (user) => {
        this.user = user
      })

    this.usersService.coreUsers$
      .pipe(untilDestroyed(this))
      .subscribe(async (coreUsersResult) => {
        let coreUsersWithoutCurrentUser = await this.arrayHelperService.removeFromArray(
          coreUsersResult,
          this.user,
          true,
          'username',
        )

        let myFriendsClean = this.myFriends.value.map(ob => ob.sk)

        let myFriendsExtraRemoved = myFriendsClean.map(str => str.replace('#friendOf#', ''))

        let friendsOnly = this.arrayHelperService.extractSubsetFromArray(
          coreUsersWithoutCurrentUser,
          myFriendsExtraRemoved,
          true,
          false,
          'username',
        )

        this.coreUsers.next(friendsOnly)

        // this.coreUsers.next(coreUsersWithoutCurrentUser)
        // this.coreUserForFilter = this.coreUsers.value
        // // parsing list of friends to be used for inviting via Title

        let listOfFriendsForInvite = this.myFriends.value

        // todo: issue here, they are comparing different things. pk vs 
        // let friendsOnly = this.arrayHelperService.extractSubsetFromArray(
        //     this.coreUserForFilter,
        //     listOfFriendsForInvite,
        //     true,
        //     false,
        //     'username',
        // )
        // this.coreUsers.next(friendsOnly)
      })
  }

  viewChecked = false
  ngAfterViewChecked() {
    if (!this.viewChecked) {
      if (this.friendInput) {
        this.viewChecked = true
        this.friendInput.blur()
      }
    }
  }

  async initEventVideos() {
    let videosRes = await this.backendApiContentService.listContentByEventID(this.inputData.eventDetails.ulid, '#', 49)
    this.videos.next(videosRes.content)
  }

  async showAdditionalImages() {

    let images = await this.backendApiContentService.listContentByEventID(this.inputData.eventDetails.ulid, 'images', 49)

    let inputData = {
      images: images.content
    }

    this.dialog.open(GhDialogWrapperComponent, {
      data: {
        title: 'Additional Images',
        component: ImageGalleryGridComponent,
        hasSubmitButton: false,
        hasCancelButton: false,
        allowParentClose: true,


        // todo this will be all images
        inputData: inputData,
        hasCloseButton: true,
      },
      height: '70%',
      width: '40%',
      disableClose: true,
      panelClass: this.isMobileScreen
        ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
        : DESKTOP_MODAL_PANEL_CLASS,
      backdropClass: 'gh-dialog-backdrop',
    })
  }



  // *********************
  //     EDIT EVENT
  // *********************

  async editEvent() {

    let inputData: iProfileAddOrEditSimpleEventInput = {
      // message: 'Permanently delete Event:',
      // data: this.inputData,
      currentUser: {
        title: this.user.title,
        username: this.user.username,
      },
      currentUserIsAdmin: true,
      isEditing: true,
      date: this.inputData.eventDetails.date,
      end: this.inputData.eventDetails.end,
      events: null,
      haven: this.inputData.haven,
      attendees: this.inputData.attendees,
      eventDetails: this.inputData.eventDetails,
      groupTitle: this.inputData.groupTitle,
      havenTitle: this.inputData.havenTitle,
      privacy: this.inputData.eventDetails.privacy,
    }

    let dialogRefCarl = this.dialog.open(GhDialogWrapperComponent, {
      data: {
        title: 'Edit Event',
        component: ProfileAddOrEditSimpleEventComponent,
        inputData: inputData,
        hasSubmitButton: true,
        hasCancelButton: true,
        allowParentClose: true,
      },
      panelClass: this.isMobileScreen
        ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
        : DESKTOP_MODAL_PANEL_CLASS,
      backdropClass: 'gh-dialog-backdrop',
      disableClose: true,
    })

    dialogRefCarl
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          let attendeesThatNeedUpdatedEventDetails = data.attendees.calendarItems

          this.backendAPIContentService.createCalendarItems(data.newEvent, data.files)

          for (let user of attendeesThatNeedUpdatedEventDetails) {
            if (user.sk.substring(0, 5) == 'e-new') {
              let newData = JSON.stringify(data.newEvent)
              let shallowCopy = JSON.parse(newData)

              shallowCopy.prefix = user.sk
              shallowCopy.gsi1pk = user.sk.replace('e-new-', '')
              shallowCopy.havenId = user.sk.replace('e-new-', '')

              this.backendAPIContentService.createCalendarItems(
                shallowCopy,
                data.files,
              )
            }
          }
        }

        this.dialogRef.close('confirm')
      })
  }

  // *********************
  //     DELETE EVENT
  // *********************
  async deleteEvent() {


    let inputData = {
      message: 'Permanently delete Event:',
      inputData: this.inputData,
      hasPassword: true,
    }



    let dialogRefCarl = this.dialog.open(GhDialogWrapperComponent, {
      data: {
        title: 'Are you sure you want to delete event?',
        component: ConfirmActionComponent,
        inputData: inputData,
        hasSubmitButton: true,
        hasCancelButton: true,
        allowParentClose: true,
      },
      panelClass: this.isMobileScreen
        ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
        : DESKTOP_MODAL_PANEL_CLASS,
      backdropClass: 'gh-dialog-backdrop',
      disableClose: true,
    })

    dialogRefCarl
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async (data) => {



        data = data.trimEnd()

        if (data && data.toLowerCase().trim() == DELETE_CONFIRMATION_PASSWORD) {
          // todo remove from UI afsdkjuhdfsakljfsdah

          this.inputData.attendees.calendarItems.forEach(async (value, index) => {
            let input = {
              pk: value.pk,
              sk: value.sk,
            }
            let deleteResult =
              await this.backendAPIContentService.deleteContentItemByPkSk(input)
          })

          let junie = await this.backendAPIMessageService.listInvitesByEventID(this.inputData.eventDetails.pk, 50)

          junie.messages.forEach(async (value) => {
            let input = {
              pk: value.pk_UID,
              sk: value.sk_type_ULID,
            }
            let deleteResult =
              await this.backendAPIMessageService.deleteMessage(input)
          })

          this.dialogRef.close('delete')
        } else {
          alert('ok, nevermind')
        }
      })
  }

  async openGameDetails(game) {
    let gameDetails = await this.backendApiGamesService.GetGame(game.pk)
    let inputData = {
      limitDataFecthing: true,
      game: gameDetails,
    }

    this.dialog.open(GhDialogWrapperComponent, {
      data: {
        title: game.title,
        component: MdGameDetailComponent,
        hasSubmitButton: false,
        hasCloseButton: true,
        hasCancelButton: false,
        inputData: inputData,
        allowParentClose: true,
      },
      maxWidth: '90%',
      panelClass: this.isMobileScreen
        ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
        : DESKTOP_MODAL_PANEL_CLASS,
      backdropClass: 'gh-dialog-backdrop',
      disableClose: true,
    })
  }

  async openAttendeeDetails(attendee) {
    this.dialog.open(GhDialogWrapperComponent, {
      maxWidth: '90%',
      backdropClass: 'gh-dialog-backdrop',
      panelClass: this.isMobileScreen
        ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
        : DESKTOP_MODAL_PANEL_CLASS,
      data: {
        title: 'Details About: '.concat(attendee.title),
        component: ProfileAboutMeFriendsFriendDetailModalComponent,
        hasSubmitButton: false,
        hasCancelButton: false,
        allowParentClose: true,
        hasCloseButton: true,

        inputData: {
          user: attendee,
        },
      },
    })
  }

  selectedUsersToInvite = null
  selectUsersToInvite(users) {
    // todo need to implement a soltion to check if user has contentPreference to ALL
    this.selectedUsersToInvite = users
  }

  async inviteMoreUsersViaViewEvent() {
    for (let user of this.selectedUsersToInvite.selections) {
      let input = {
        sentToUID: user.username,
        sentToTitle: user.title,
        sentFromUID: this.user.username,
        sentFromTitle: this.user.title,
        eventID: this.inputData.eventDetails.ulid,
        type: 'EventInvite',
        extendedProps: this.inputData.eventDetails,
      }

      if (input && 'isInMyEvent' in input.extendedProps) {
        // needed to remove extra data
        delete input.extendedProps.isInMyEvent
      }
      this.backendAPIMessageService.createInviteItem(input)
    }

    this.selectedUsersToInvite = null
    this.snackbarService.openSuccessSnackBar('Invitation Sent!')
  }

  selectedUserId
  selectedUserTitle
  selectUser(user) {
    this.selectedUserId = user[0].username
    this.selectedUserTitle = user[0].title
  }

  getAttendeeImage(index) {
    return this.attendees[index] && this.attendees[index].mainImageFiles
      ? this.attendees[index].mainImageFiles[0]
      : window.location.origin + '/assets/images/defaults/profile-placeholder.png'
  }

  getInvitedImage(index) {
    return this.listOfInvitedUsers[index] && this.listOfInvitedUsers[index].mainImageFiles
      ? this.listOfInvitedUsers[index].mainImageFiles[0]
      : window.location.origin + '/assets/images/defaults/profile-placeholder.png'
  }


  async fetchImagesForEvent() {
    let res = await this.backendAPIContentService.listContentByEventID(this.inputData.eventDetails.ulid, 'images', 49)

    let unshapedImages = res.content
    let reshapedImages
    let indexToStartAdditionalImagesLoop = 0

    if (this.inputData.eventDetails.mainImageFiles && this.inputData.eventDetails.mainImageFiles.length > 0) {
      reshapedImages = [{ imgPath: this.inputData.eventDetails.mainImageFiles, isMainImage: true }]
    }
    else if (unshapedImages && unshapedImages.length > 0) {
      indexToStartAdditionalImagesLoop = 1
      reshapedImages = [{ imgPath: unshapedImages[0].paths, isMainImage: true }]
    }
    else {
      reshapedImages = [{
        imgPath: this.inputData.eventDetails.mainImageFiles && this.inputData.eventDetails.mainImageFiles.length > 0 ? this.inputData.eventDetails.mainImageFiles : [
          window.location.origin + '/assets/images/defaults/placeholder-event.png',
          window.location.origin + '/assets/images/defaults/placeholder-event.png',
          window.location.origin + '/assets/images/defaults/placeholder-event.png',
          window.location.origin + '/assets/images/defaults/placeholder-event.png',
        ],
        isMainImage: true,
      }]
    }

    for (var i = indexToStartAdditionalImagesLoop; i < unshapedImages.length; i++) {
      reshapedImages.push({
        imgPath: unshapedImages[i].paths,
        isMainImage: false,
      })
    }

    this.thumbs.next(reshapedImages)
  }

}
