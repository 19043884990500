<div class="row">
    <div class="col-12">
        <mat-card class="objective-info-card mat-elevation-z6">
            <div class="row gh-card-header-row">
                <div class="col-12">
                    <mat-card-title class="gh-sm-title">{{selectedGame.title}}</mat-card-title>
                    <mat-divider></mat-divider>
                </div>
            </div>

            <div class="row content-row">

                <div class="col-12 col-xs-12 col-sm-6 col-m-6 col-lg-6">

                    <div class="row">
                        <div class="col-12">
                            <span class="hoverable" matTooltip="Number of players according to publisher"
                                style="cursor: default; margin-left: -0.5rem" matTooltipPosition="above">
                                <span class="inline-span">
                                    <mat-icon class="gh-icon tab-icon">people</mat-icon>
                                </span>
                                <span class="inline-span">
                                    <mat-card-subtitle>
                                        {{getMinMaxPlayers() | nullPlaceholder}}
                                    </mat-card-subtitle>
                                </span>
                            </span>

                            <span class="hoverable pl-1" matTooltip="Best number of players according to the community"
                                matTooltipPosition="above" (click)="openSubmitPlayerCount()">

                                <!-- ! uncomment this out and remove magnifying glass once ben is ready with math -->
                                <!-- <span class="inline-span">
                                    <mat-icon class="gh-icon tab-icon gh-icon-success">
                                        people
                                    </mat-icon>
                                </span>
                                <span class="inline-span">
                                    <mat-card-subtitle>
                                        {{getVotedMinMaxPlayers() | nullPlaceholder}}
                                    </mat-card-subtitle>
                                </span> -->

                                <span class="inline-span">
                                    <mat-icon class="gh-icon tab-icon gh-icon-success">
                                        zoom_in
                                    </mat-icon>
                                </span>
                            </span>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <!-- <span class="hoverable" matTooltip="Game time in minutes" -->
                            <span class="hoverable" style="cursor: default; margin-left: -0.5rem"
                                matTooltipPosition="above">
                                <span class="inline-span">
                                    <mat-icon class="gh-icon tab-icon">hourglass_full
                                    </mat-icon>
                                </span>
                                <span class="inline-span">

                                    <!-- todo Terra or We Care -->

                                    <mat-card-subtitle>

                                        <!-- MIN = MAX -->
                                        <p *ngIf="selectedGame.minPlaytime == selectedGame.maxPlaytime">
                                            {{selectedGame.minPlaytime}}
                                        </p>

                                        <!-- ONLY MIN EXISTS -->
                                        <p *ngIf="selectedGame.minPlaytime && selectedGame.maxPlaytime == null">
                                            {{selectedGame.minPlaytime}}
                                        </p>

                                        <!-- ONLY MAX EXISTS -->
                                        <p *ngIf="selectedGame.maxPlaytime && selectedGame.minPlaytime == null">
                                            {{selectedGame.maxPlaytime}}
                                        </p>

                                        <!-- NEITHER EXIST -->
                                        <p *ngIf="selectedGame.minPlaytime == null && selectedGame.maxPlaytime == null">
                                            ?
                                        </p>

                                        <!-- BOTH EXIST BUT ARE DIFFERENT -->
                                        <p
                                            *ngIf="(selectedGame.minPlaytime != selectedGame.maxPlaytime) && selectedGame.maxPlaytime">
                                            {{selectedGame.minPlaytime}}-{{selectedGame.maxPlaytime }}
                                        </p>

                                    </mat-card-subtitle>
                                </span>


                                <!-- <span class="hoverable pl-1" matTooltip="Duration based on actual plays." matTooltipPosition="above" (click)="openViewRealDuration()"> -->
                                <!-- <span class="hoverable pl-1" (click)="openViewRealDuration()">
                                    <span class="inline-span">
                                        <mat-icon class="gh-icon tab-icon gh-icon-success">
                                            zoom_in
                                        </mat-icon>
                                    </span>
                                </span> -->
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <span>
                                <span class="inline-span">
                                    <mat-card-subtitle>
                                        Released: {{selectedGame.yearPublished}}</mat-card-subtitle>
                                </span>
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <span class="hoverable" matTooltip="{{selectedGame.officialLink |
                                nullPlaceholder}}" matTooltipPosition="above">
                                <span class="inline-span">
                                    <mat-card-subtitle (click)="navigateToOfficialPage()">
                                        Website</mat-card-subtitle>
                                </span>
                                <span class="inline-span">
                                    <mat-icon (click)="navigateToOfficialPage()"
                                        class="gh-icon tab-icon gh-icon-primary">link
                                    </mat-icon>
                                </span>
                            </span>
                        </div>
                    </div>

                </div>

                <div class="col-12 col-xs-12 col-sm-6 col-m-6 col-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <span matTooltipPosition="above" style="cursor: default;">
                                <span class="inline-span">
                                    <mat-card-subtitle>Ages: {{getMinSuggestedAge() | nullPlaceholder}}
                                    </mat-card-subtitle>
                                </span>
                            </span>
                        </div>
                    </div>

                    <div class="row" *ngIf="hasExpansions">
                        <div class="col-12">
                            <mat-card-subtitle>Expansions:</mat-card-subtitle>
                            <p class="neg-margin-top"
                                matTooltip="{{expansionsString.length > 30 ? expansionsString : ''}}"
                                matTooltipPosition="above">{{truncate(expansionsString, 30)}}
                            </p>
                        </div>
                        <div class="col-12" *ngIf="isExpansion">
                            <mat-card-subtitle>Expansion of:</mat-card-subtitle>
                            <p class="neg-margin-top">{{parentItem.title}}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <span class="inline-span">
                                <mat-card-subtitle>
                                    Status:
                                </mat-card-subtitle>
                            </span>
                            <span class="inline-span ml-2">
                                <p>
                                    {{selectedGame.printStatus | gamePrintStatus}}
                                </p>
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <span>
                                <span class="inline-span">
                                    <mat-card-subtitle>
                                        Publisher:
                                    </mat-card-subtitle>
                                </span>
                                <span class="inline-span ml-2 modal-link hoverable gh-link" style="cursor: pointer;">
                                    <p *ngIf="selectedGame.publishers && selectedGame.publishers[0]"
                                        (click)="fetchPublisherGames()">
                                        {{selectedGame.publishers[0].title
                                        | nullPlaceholder}}</p>
                                </span>
                                <span (click)="openFullCredits()" class="hoverable"
                                    *ngIf="selectedGame.publishers && selectedGame.publishers[1]">
                                    <strong>...</strong>
                                </span>
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <span>
                                <span class="inline-span">
                                    <mat-card-subtitle class="hoverable">
                                        Developer:
                                    </mat-card-subtitle>
                                </span>
                                <span style="cursor: pointer" class="inline-span ml-2 modal-link hoverable gh-link"
                                    (click)="fetchDeveloperGames()">
                                    <p *ngIf="selectedGame.designers && selectedGame.designers[0]">
                                        {{selectedGame.designers[0].title | nullPlaceholder}}</p>
                                </span>
                                <span class="hoverable" *ngIf="selectedGame.designers && selectedGame.designers[1]">
                                    <strong (click)="openFullCredits()"> ...</strong>
                                </span>
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <span>
                                <span class="inline-span">
                                    <mat-card-subtitle class="hoverable">
                                        Artist:
                                    </mat-card-subtitle>
                                </span>
                                <span style="cursor: pointer" class="inline-span ml-2 modal-link hoverable gh-link"
                                    (click)="fetchArtistGames()">
                                    <p *ngIf="selectedGame.artists && selectedGame.artists[0]">
                                        {{selectedGame.artists[0].title | nullPlaceholder}}</p>
                                </span>
                                <span class="hoverable" *ngIf="selectedGame.artists && selectedGame.artists[1]">
                                    <strong (click)="openFullCredits()"> ...</strong>
                                </span>
                            </span>
                        </div>
                    </div>







                    <div class="row" *ngIf="selectedGame.isCrowdfunded">
                        <div class="col-12">
                            <span class="inline-span">
                                <mat-card-subtitle>Site:</mat-card-subtitle>
                            </span>
                            <span class="inline-span">
                                &nbsp; {{ selectedGame.crowdfundingSite }}
                                <!-- &nbsp; {{selectedGameBulkData.crowdfundingSite | crowdfundingType}} -->
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="hoverable" matTooltipPosition="above">
                                <span class="inline-span">
                                    <mat-card-subtitle (click)="navigateToOfficialPage()">
                                        Crowdfunding Website</mat-card-subtitle>
                                </span>
                                <!-- <span class="inline-span">
                                <mat-icon (click)="navigateToOfficialPage()"
                                    class="gh-icon tab-icon gh-icon-primary">link
                                </mat-icon>
                            </span> -->
                                <span class="inline-span" (click)="navigateToOfficialPage()">
                                    <p class="gh-link"
                                        matTooltip="{{selectedGame.crowdfundingLink.length > 40 ? selectedGame.crowdfundingLink : ''}}"
                                        matTooltipPosition="above">
                                        {{truncate(selectedGame.crowdfundingLink,
                                        20)}}
                                    </p>
                                </span>
                            </span>
                        </div>

                        <!-- TODO COUNTDOWN TIMER IS FUTURE FEATURE, WILL BE SOMETHING LIKE THIS -->
                        <!-- <div class="col-12 w-100 countdown-container"> 
                            <app-countdown-timer [_title]="timeUntil" [_date]="releaseDate">
                            </app-countdown-timer>
                        </div> -->
                    </div>

                    <div class="row" *ngIf="showFullDetailsButton">
                        <div class="col-12 float-right">
                            <button mat-raised-button class="gh-button gh-button-secondary"
                                (click)="openFullCredits()">Full Credits</button>
                        </div>
                    </div>

                </div>





            </div>



        </mat-card>
    </div>
</div>