<div class="row">
    <div class="col-12">
        <mat-card class="mat-elevation-z6 subjective-info-card">
            <div class="mt row">
                <!-- <div class="col-4">
                    <div class="row">
                        <div class="col-12">
                            <mat-card-subtitle style="color: red; text-decoration: line-through;" class="gh-md-subtitle">Players like you rate this:
                            </mat-card-subtitle>
                        </div>
                        <div class="col-12">
                            <mat-card-title class="margin-top-negative gh-xsm-title">
                                Overwhelmingly Positive
                            </mat-card-title>
                        </div>
                        <div class="col-12">
                            <mat-card-subtitle>
                                * Based on {{howManyRatings}} Ratings
                            </mat-card-subtitle>
                        </div>
        
                    </div>
                </div> -->
            </div>

            <div class="row">
                <div class="mt-2 mb-3 col-12">
                    <mat-card-subtitle class="gh-md-subtitle">Your Rating: </mat-card-subtitle>
                </div>
                <div class="p-0 col-12">
                    <div
                        *ngIf="user.userLevel > onboardingUserLevelThreshold; else onboardingButton"
                    >
                        <div style="margin-top: -2rem; margin-left: 0.5rem">
                            <app-gh-ratings
                                [_isReadOnly]="false"
                                [_rating]="gameRating.rating"
                                (submitEmitter)="setStarRating($event)"
                                (clearEmitter)="setStarRating(null)"
                            >
                            </app-gh-ratings>
                        </div>
                    </div>

                    <ng-template #onboardingButton>
                        <button
                            mat-raised-button
                            (click)="openBulkRateGames()"
                            id="continue-onboarding-button"
                            class="m-3 gh-button gh-button-secondary"
                        >
                            Unlock Game Ratings by Taking the Onboarding Survey
                        </button>

                        <!-- <mat-icon *ngIf="isMobileScreen" class="gh-icon gh-icon-clickable gh-icon-warning wiggle-icon"
                            (click)="openBulkRateGames()">
                            error
                        </mat-icon> -->
                    </ng-template>
                </div>
            </div>

            <div class="mt-2 row">
                <!-- <div class="col-sm-12 col-12 col-lg-8 col-md-12">
                    <div class="tag-container">
                        <mat-chip-list>
                            <span class="m-3">Tags:</span>
                            <mat-chip class="no-wrap" *ngFor="let tag of tags">{{tag}}
                            </mat-chip>
                            <span class="see-more-elipse">
                                <mat-icon class="ml-3 gh-icon gh-icon-clickable gh-icon-edit"
                                    (click)="editTags()">edit</mat-icon>
                            </span>
                        </mat-chip-list>
                    </div>
                </div> -->
                <div class="col-sm-12 col-12 col-lg-4 col-md-12">
                    <!-- <span class="inline-span user-opinions-span-right user-opinions-span">
                            <button mat-raised-button class="gh-button gh-button-primary"
                                (click)="openSubmitPlayerCount()"
                                matTooltip="Submit your opinion of the ideal number of players."
                                matTooltipPosition="above">{{userHasSubmittedIdealPlayers ? 'Edit Ideal Players'
                                : 'Submit Ideal Players'}}
                            </button>
                        </span> -->
                    <!-- <span class="inline-span user-opinions-span-left user-opinions-span">
                            <button mat-raised-button class="gh-button gh-button-secondary"
                                (click)="fetchSelectedGameImagesByLikes()"
                                matTooltip="Submit a comprehensive or video review."
                                matTooltipPosition="above">Fetch Images by likes</button>
                        </span> -->
                </div>
            </div>
        </mat-card>
    </div>
</div>
