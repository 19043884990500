import { HostListener, Inject, Injectable } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subscription, interval, timer } from 'rxjs';
import { BackendApiUserTrackingService } from 'src/app/backend-api-services/backend-api-user-tracking.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';
import { DOCUMENT } from '@angular/common';
import { switchMap, tap, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {


  userIsActive = new BehaviorSubject<boolean>(true);
  userIsActive$ = this.userIsActive.asObservable();


  coreUsers = new BehaviorSubject(null)
  coreUsers$ = this.coreUsers.asObservable()

  myFriends = new BehaviorSubject(null)
  myFriends$ = this.myFriends.asObservable()


  lastActivityTime: number;

  constructor(

    private backendApiUsersService: BackendAPIUsersService,
    private backendApiUserTrackingService: BackendApiUserTrackingService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.startWatchingUserActivity()
    this.initCoreUserData()
    this.initMyFriends()
    this.lastActivityTime = new Date().getTime();
  }



  ngOnInit() {
    this.startWatchingUserActivity();

  }

  setUserIsActive(active: boolean) {
    this.userIsActive.next(active);
  }

  getUserIsActive() {
    return this.userIsActive.asObservable();
  }

  startWatchingUserActivity() {

    interval(30000).pipe( // 5 seconds
      tap(() => {
        this.userIsActive.next(false)
      })
    ).subscribe(() => {
    });
  }

  async initCoreUserData() {
    let result = await this.backendApiUsersService.listAllUserCoreData(19999)
    this.coreUsers.next(result.coreUsers)
  }

  user
  async initMyFriends() {
    await this.backendApiUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(async user => {
      if (user) {
        this.user = user
        let myFriends = await this.backendApiUserTrackingService.listUserTrackingItemsByType(this.user.username, 'friendOf', 500)
        this.myFriends.next(myFriends.userTrackingItems)
      }
    })
  }







}
